import React from 'react';

const TouD4to9pmDetails = () => {
  return (
    <div>
      <h2>Rate Plan: Nighttime Savers</h2>

      <p>The Nighttime Savers Rate delivers nighttime savings, 12 months a year. This rate is great for EV owners, or if you use electricity at night.</p>

      <h2>How it works:</h2>

      <p>Reduce your energy costs when you shift electricity use, including EV charging, to "super off-peak" hours (11 p.m. - 6 a.m. Monday - Friday).</p>

      <p>Take advantage of cost savings all day Saturday - Sunday with "super off-peak" pricing.</p>


      <h2>What it means for you:</h2>

      <p>If you can be flexible about what time of day you use electricity, this three-tiered rate could help you save money on your bill.</p>

      <a href="https://www.consumersenergy.com/residential/rates/electric-rates-and-programs/rate-plan-options/nighttime-savers" rel="noopener noreferrer" target="_blank"><button className='details-button'>Learn More</button></a>
    </div>
  );
};

export default TouD4to9pmDetails;
