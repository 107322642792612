import scheduleD from './rates/scheduleD';
import touD4To9pm from './rates/touD4To9pm';
import touD5to8pm from './rates/touD5To8pm';
// import touDPrime from './rates/touDPrime';
import ScheduleDDetails from './rates/ScheduleDDetails';
import TouD4to9pmDetails from './rates/TouD4To9pmDetails';
import TouD5to8pmDetails from './rates/TouD5To8pmDetails';
// import TouDPrimeDetails from './rates/TouDPrimeDetails';

const rates = {
  'TOU-D-4to9': {
    id: 'TOU-D-4to9',
    title: ['Nighttime Savers'],
    subTitle: ['(Recommended)'],
    data: touD4To9pm,
    detailsComponent: TouD4to9pmDetails,
  },
  'TOU-D-5to8': {
    id: 'TOU-D-5to8',
    title: ['Residential Smart Hours'],
    data: touD5to8pm,
    detailsComponent: TouD5to8pmDetails,
  },
  'TIERED': {
    id: 'TIERED',
    title: ['Summer Rate'],
    subTitle: ['(Standard)'],
    data: scheduleD,
    detailsComponent: ScheduleDDetails,
  },
  // 'TOU-D PRIME': {
  //   id: 'TOU-D PRIME',
  //   title: ['TOU-D-PRIME'],
  //   data: touDPrime,
  //   detailsComponent: TouDPrimeDetails,
  // },
};

export default rates;
