import React from "react";
import "./Footer.scss";
import smallLogo from "../../../../client_customizations/assets/images/small-logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

import face from "../../../../client_customizations/assets/images/footerFacebook.png";
import twitter from "../../../../client_customizations/assets/images/footerTwitter.png";
import linkedin from "../../../../client_customizations/assets/images/footerLinkedin.png";
import insta from "../../../../client_customizations/assets/images/footerInstagram.png";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";

const Footer = ({ language, changeLanguage }) => {
  return (
    <div className="Footer">
      <div className="container pt-5 pb-4">
        <div className="row">
          <div className="col-md-2 mb-3 itemList">
            <h6 className="h3">RESIDENTIAL</h6>

            <a href="https://www.consumersenergy.com/residential/billing-and-payment">
              <p>Billing & Payment</p>
            </a>

            <a href="https://www.consumersenergy.com/outages/outage-center?itm_source_h=menu&itm_medium_h=res_menu&itm_campaign_h=outage-center&itm_content_h=outage-center">
              <p>Outage Center</p>
            </a>

            <a href="https://www.consumersenergy.com/residential/rates">
              <p>Residential Rates</p>
            </a>

            <a href="https://www.consumersenergy.com/residential/programs-and-services">
              <p>Programs & Services</p>
            </a>

            <a href="https://www.consumersenergy.com/residential/save-money-and-energy">
              <p>Save Money & Energy</p>
            </a>

            <a href="https://www.consumersenergy.com/residential/renewable-energy">
              <p>Renewable Energy</p>
            </a>

            <a href="https://www.consumersenergy.com/residential/start-stop-service">
              <p>Start, Stop Or Transfer Service</p>
            </a>

            <a href="https://www.consumersenergy.com/residential/avoiding-scams">
              <p>Avoiding Scams</p>
            </a>

            <a href="https://www.applianceserviceplan.com/?utm_source=customer-search&utm_medium=ce-site-search&utm_campaign=appliance-service-plan&utm_content=search-term">
              <p>Appliance Service Plan*</p>
            </a>

            <a href="https://consumersenergystore.com/homepage?utm_campaign=eeprograms&utm_source=ce-website&utm_medium=vanity-url&utm_content=residential-nav">
              <p>Online Store</p>
            </a>

            <a href="https://www.consumersenergy.com/residential/save-money-and-energy/rebates">
              <p>Rebates</p>
            </a>
          </div>

          <div className="col-md-2 mb-3 itemList">
            <h6 className="h3">BUSINESS</h6>

            <a href="https://www.consumersenergy.com/business/business-center">
              <p>Business Solutions</p>
            </a>

            <a href="https://www.consumersenergy.com/business/account-management">
              <p>Account Management</p>
            </a>

            <a href="https://www.consumersenergy.com/business/energy-efficiency">
              <p>Energy Efficiency</p>
            </a>

            <a href="https://www.consumersenergy.com/business/energy-use-dashboard">
              <p>Energy Dashboard</p>
            </a>

            <a href="https://www.consumersenergy.com/business/products-and-services">
              <p>Products & Services</p>
            </a>

            <a href="https://www.consumersenergy.com/business/avoiding-scams">
              <p>Avoiding Scams</p>
            </a>

            <a href="https://www.consumersenergy.com/business/renewable-energy">
              <p>Renewable Energy</p>
            </a>

            <a href="https://www.consumersenergy.com/business/rates">
              <p>Rate Plans</p>
            </a>

            <a href="https://www.consumersenergy.com/business/small-business-news-and-tips">
              <p>News & Tips</p>
            </a>
          </div>

          <div className="col-md-2 mb-3 itemList">
            <h6 className="h3">COMMUNITY </h6>

            <a href="https://www.consumersenergy.com/community/safety">
              <p>Emergencies & Safety</p>
            </a>

            <a href="https://www.consumersenergy.com/community/corporate-giving">
              <p>Corporate Giving</p>
            </a>

            <a href="https://www.consumersenergy.com/community/education">
              <p>Education</p>
            </a>

            <a href="https://www.consumersenergy.com/community/foundation">
              <p>Foundation</p>
            </a>

            <a href="https://www.consumersenergy.com/community/sustainability">
              <p>Sustainability</p>
            </a>
          </div>

          <div className="col-md-2 mb-3 itemList">
            <h6 className="h3">COMPANY</h6>

            <a href="https://www.consumersenergy.com/company/who-we-are">
              <p>Who We Are</p>
            </a>

            <a href="https://www.consumersenergy.com/company/careers">
              <p>Careers</p>
            </a>

            <a href="https://www.consumersenergy.com/company/become-a-supplier">
              <p>Become a Supplier</p>
            </a>

            <a href="https://www.consumersenergy.com/company/clean-energy">
              <p>Clean Energy</p>
            </a>

            <a href="https://www.consumersenergy.com/company/economic-development">
              <p>Economic Development</p>
            </a>

            <a href="https://www.consumersenergy.com/company/electric-generation">
              <p>Electric Generation</p>
            </a>

            <a href="https://www.consumersenergy.com/company/media">
              <p>Media </p>
            </a>

            <a href="https://www.consumersenergy.com/company/natural-gas-operations">
              <p>Natural Gas Operations</p>
            </a>

            <a href="https://www.consumersenergy.com/company/trees-and-power-lines">
              <p>Trees and Power Lines</p>
            </a>
          </div>

          <div className="col-md-2 mb-3 contactList">
            <div className="row">
              <a href="https://www.facebook.com/ConsumersEnergyMichigan/">
                <img alt="CE Facebook" src={face} />
              </a>

              <a href="https://twitter.com/ConsumersEnergy">
                <img alt="CE Twitter" src={twitter} />
              </a>

              <a href="https://www.linkedin.com/company/consumersenergy/">
                <img alt="CE Linkedin" src={linkedin} />
              </a>

              <a href="https://www.instagram.com/consumersenergy/">
                <img alt="CE Instagram" src={insta} />
              </a>
            </div>

            <p>
              <b>GENERAL RESIDENTIAL</b>
            </p>
            <p className="blue">
              <a href="tel: +1-800-477-5050">
                <FontAwesomeIcon icon={faPhoneAlt} />
                <b>800-477-5050</b>
              </a>
            </p>
            <p>M-F: 7 a.m. - 6 p.m.</p>
            <p>Sat: 8 a.m. - 2 p.m.</p>
            <p>
              <b>BUSINESS</b>
            </p>
            <p className="blue">
              <a href="tel: +1-800-805-0490">
                <FontAwesomeIcon icon={faPhoneAlt} />
                <b>800-805-0490</b>
              </a>
            </p>
            <p>M-F: 8 a.m - 4:30 p.m.</p>
          </div>
        </div>

        <div className="smallLogo">
          <img alt="CE logo" src={smallLogo} />
        </div>

        <div className="copyright">
          <span style={{ fontFamily: "Arial, Helvetica" }}>&copy;
            {(new Date().getFullYear().toString())} Consumers energy
          </span>
        </div>
        {/* <div className="copyright">
          <span style={{ fontFamily: "Arial, Helvetica" }}> &copy;
            {(new Date().getFullYear().toString()) + " "} J.D. Power ZappyRide (c).
            <a rel="noopener noreferrer" target="_blank" href="https://zappyride.com/legal-notices">Legal notices.</a>
          </span>
        </div> */}

        <div className="row institutionLinks">
          <a href="https://www.consumersenergy.com/privacy">
            <p>Privacy | </p>
          </a>
          <a href="https://www.consumersenergy.com/accessibility">
            <p>Accessibility Statement | </p>
          </a>
          <a href="https://www.consumersenergy.com/contact-us">
            <p>Contact | </p>
          </a>
          <a href="https://www.consumersenergy.com/terms-and-conditions">
            <p>Terms Of Use | </p>
          </a>
          <a href="https://www.consumersenergy.com/security">
            <p>Security | </p>
          </a>
          <a href="https://www.consumersenergy.com/social-media-guidelines">
            <p>Social Media Guidelines</p>
          </a>
        </div>

        <div className="disclaimer_container" style={{marginLeft:"-10px"}}>
                     <DisclaimerComponent clientName="zappyride" textStyle={{fontFamily:"Open Sans, Helvetica, sans-serif",fontSize:"12px" }} />
       </div>

        <div className="disclaimer">
          *Appliance Service Plan Is Not Regulated By The Michigan Public
          Service Commission. Appliance Service Plan Does Not Affect Your
          Regulated Utility Service.
        </div>
      </div>
    </div>
  );
};

export default Footer;
