import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { ValidateFiveDigitCode } from "../../../utils/Helpers/PostalCodeValidator";
import ToolTip from "../../../components/shared/ToolTip/ToolTip";
import getUserPref from "../../../context/UserPrefs/getUserPref";

import ChooseCanTurnInClunker from "../../../client_customizations/components/InputComponents/ChooseCanTurnInClunker/ChooseCanTurnInClunker";

import SlideHouseholdIncome from "../../../components/InputComponents/SlideHouseholdIncome/SlideHouseholdIncome";
import SelectHouseholdSize from "../../../client_customizations/components/InputComponents/SelectHouseholdSize/SelectHouseholdSize";
// import SelectElectricityProvider from "../../../client_customizations/components/InputComponents/SelectElectricityProvider/SelectElectricityProvider.js";
import ChooseTaxFilingStatus from "../../../client_customizations/components/InputComponents/ChooseTaxFilingStatus/ChooseTaxFilingStatus.js";
import ControlledInputZipcode from "../../../components/InputComponents/InputZipcode/ControlledInputZipcode";
import { FormattedMessage, useIntl } from "react-intl";

import "./IncentivePreferences.scss";

const IncentivePreferences = ({
  electricVehicles,
  titleText,
  btnSubtle,
  btnText,
  incentives,
  location,
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const [workingZipcode, setWorkingZipcode] = useState(
    getUserPref("zipcode")
  );

  const handleFormSubmit = () => {
    if (ValidateFiveDigitCode(workingZipcode)) {
      userPrefs.syncWorkingZipcode();
      userPrefs.toggleIncentivePrefsModal();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !userPrefs.zipcodeIsUpdating) {
      handleFormSubmit();
    }
  };

  const RenderButton = () => {
    let btnTextUpdating = userPrefs.zipcodeIsUpdating
      ? "Updating..."
      : intl.formatMessage({
          id: "incentives.incentiveEligibility",
          defaultMessage: "Update Incentive Eligibility",
        });

    return (
      <button
        type="button"
        className="personalize-button centered"
        onClick={() => handleFormSubmit()}
        disabled={userPrefs.zipcodeIsUpdating}
      >
        {btnTextUpdating}
      </button>
    );
  };
  const RenderItem = ({ component, tooltip_id, title_id, message }) => (
    <div>
      <div className="label-select">
        <FormattedMessage
          id={title_id}
          defaultMessage={"Item Title"}
          description={"Item Title"}
        />
        <ToolTip message={message} id={tooltip_id} />
      </div>
      {component}
    </div>
  );

  return (
    <>
      {/* <button
        onClick={() => userPrefs.toggleIncentivePrefsModal(true)}
        className={btnSubtle ? "btn btn-sm button" : "btn btn-ae button"}
        type="button"
      >
        {btnText}
      </button> */}
      <Modal
        isOpen={userPrefs.showIncentivePrefsModal}
        toggle={() => userPrefs.toggleIncentivePrefsModal()}
      >
        <ModalHeader toggle={userPrefs.toggleIncentivePrefsModal}>
          {intl.formatMessage
            ? intl.formatMessage({
                id: "personalizeIncentives",
                defaultMessage: "Personalize Incentives",
              })
            : "Personalize Incentives"}
        </ModalHeader>
        <ModalBody>
          <div className="IncentivePreferences">
            <div className="input-well contained" onKeyDown={handleKeyDown}>
              <div className="grid-container">
                <div className="grid-item">
                  <ControlledInputZipcode
                    zipcode={getUserPref("zipcode")}
                    handleSubmit={(e) => {
                      handleFormSubmit();
                    }}
                    workingZipcode={workingZipcode}
                    setZipcode={(e) => {
                      setWorkingZipcode(e.target.value);
                      if (ValidateFiveDigitCode(e.target.value)) {
                        userPrefs.set({ workingZipcode: e.target.value });
                      }
                    }}
                    id="input-zipcode-for-rate-comp-launch"
                    isUpdating={userPrefs.zipcodeIsUpdating}
                    isNotFound={userPrefs.zipcodeIsNotFound}
                    hideValidateRequest
                  />
                </div>
                {/* <div className="grid-item pushed-up">
                  <SelectElectricityProvider location={location} />
                </div> */}
                <div className="grid-item">
                  <RenderItem
                    component={<SlideHouseholdIncome />}
                    tooltip_id={"household_income_tooltip"}
                    title_id={"incentives.householdIncome"}
                    message={
                      <FormattedMessage
                        id="incentives.householdIncomeTooltip"
                        defaultMessage="Combine income from all household members. Used to determine incentive eligibility."
                        description="Item Title"
                      />
                    }
                  />
                </div>
                <div className="grid-item">
                  <RenderItem
                    component={<SelectHouseholdSize />}
                    tooltip_id={"household_size_tooltip"}
                    title_id={"incentives.householdSize"}
                    message={
                      <FormattedMessage
                        id="incentives.householdSizeTooltip"
                        defaultMessage="Include all members of your household (spouse, deoendants, etc). Used to determine incentived eligibility."
                        description="Item Title"
                      />
                    }
                  />
                </div>
                <div className="grid-item">
                  <RenderItem
                    component={<ChooseTaxFilingStatus />}
                    tooltip_id={"filing_status_tooltip"}
                    title_id={"incentives.taxFilingStatus"}
                    message={
                      <FormattedMessage
                        id="incentives.taxFilingStatusTooltip"
                        defaultMessage="Used to determine incentive eligibility."
                        description="Item Title"
                      />
                    }
                  />
                </div>
                <div className="grid-item">
                  <RenderItem
                    component={<ChooseCanTurnInClunker />}
                    tooltip_id={"trade_in_tooltip"}
                    title_id={"incentives.tradeIn"}
                    message={
                      <FormattedMessage
                        id="incentives.retiringVehicleTooltip"
                        defaultMessage="Retiring an old gas vehicle may be required for certain incentives."
                        description="Item Title"
                      />
                    }
                  />
                </div>
              </div>
              <div className="form-group centered">
                <RenderButton />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default IncentivePreferences;

IncentivePreferences.propTypes = {
  electricVehicles: PropTypes.array,
  titleText: PropTypes.string,
  btnText: PropTypes.string,
  btnSubtle: PropTypes.bool,
  incentives: PropTypes.array,
  location: PropTypes.object,
};
