import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import Select from "../../../../components/shared/InputElements/Select";
import { useIntl } from "react-intl";

const ChooseTaxFilingStatus = ({ id = "tax-filing-status", ...rest }) => {
  const userPrefs = useContext(UserPrefsContext);
  const intl = useIntl();

  return (
    <>
      <div>
        <Select
          id={id}
          isSpecial={true}
          value={userPrefs.get("taxFilingStatus")}
          optionNames={[
            intl.formatMessage({
              id: "incentives.single",
              defaultMessage: "Single",
            }),
            intl.formatMessage({
              id: "incentives.married",
              defaultMessage: "Married",
            }),
            intl.formatMessage({
              id: "incentives.headOfHousehold",
              defaultMessage: "Head of Household",
            }),
          ]}
          optionValues={["single", "joint", "head_of_household"]}
          handler={(e) => userPrefs.set({ taxFilingStatus: e.target.value })}
          {...rest}
        />
      </div>
    </>
  );
};

export default ChooseTaxFilingStatus;

ChooseTaxFilingStatus.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
