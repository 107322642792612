import times from "lodash/times";
import {HOLIDAYS} from "./holidays";

// https://library.sce.com/content/dam/sce-doclib/public/regulatory/tariff/electric/schedules/residential-rates/ELECTRIC_SCHEDULES_D.pdf
// The Tiered charges are the same for winter and summer, so they don't technically
// need to be split into 6 separate tiers.
// However, the rate tariff defines them that way, so presumably they may change at
// some point.

const createRateComponent = (months, charge, hours, season) => ({
  charge: charge,
  months: months,
  daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
  hourStarts: hours,
  exceptForDays: HOLIDAYS,
  name: season,
});

const createAllRateComponents = () => {
  const winterRateComponents = createRateComponent(
    [1, 2, 3, 4, 5, 10, 11, 12], //months
    0.159, //charge
    times(24, Number), //hours
    "Winter" //season
  );
  const summerRateComponents1 = createRateComponent(
    [6, 7, 8, 9],
    0.167,
    [...times(14, Number), ...times(5, (i) => Number(i + 19))],
    "Summer"
  );
  const summerRateComponents2 = createRateComponent(
    [6, 7, 8, 9],
    0.223,
    times(5, (i) => Number(i + 14)),
    "Summer"
  );

  return [winterRateComponents, summerRateComponents1, summerRateComponents2];
};

const scheduleD = (territory) => {
  return {
    name: "TOU D Tiered",
    title: "TOU D Tiered",
    rateElements: [
      {
        name: "Energy Charges",
        rateElementType: "EnergyTimeOfUse",
        rateComponents: createAllRateComponents(),
      },
    ],
  };
};

export default scheduleD;
