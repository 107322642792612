import React, { useContext } from "react";
import Switch from "react-switch";
import chartOptions from "./chartOptions";
import { Bar } from "react-chartjs-2";
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';
import './RateComparisonChart.scss';

const RateComparisonChart = ({
  title,
  chartData,
  savingsColor,
  children,
  className,
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const {
    selectedEnergyCostIds,
  } = userPrefs.get();

  const options = chartOptions({ savingsColor });

  const handleClickDataset = (id) => {
    const current = userPrefs.get("selectedEnergyCostIds");
    userPrefs.set({
      selectedEnergyCostIds: current.includes(id) ?
        current.filter(existingId => existingId !== id) :
        [...current, id]
    });
  };

  const filteredChartData = {
    ...chartData,
    datasets: chartData.datasets.filter(dataset => selectedEnergyCostIds.includes(dataset.id)),
  };

  return (
    <div className={`RateAdvisorChart ${ className }`}>
      <Bar data={filteredChartData} options={options} />
      <div className="d-flex chart-controls">
        <div>
          {chartData.datasets.map((dataset) => {
            return (
              <div key={dataset.id} className="d-inline-block mr-2 chart-data-toggle">
                <Switch
                  id={dataset.id}
                  width={40}
                  height={20}
                  checked={selectedEnergyCostIds.includes(dataset.id)}
                  onChange={() => handleClickDataset(dataset.id)}
                  onColor="#0079C1"
                  offColor="#E2E5E2"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
                <label htmlFor={dataset.id}>{dataset.label}</label>
              </div>
            );
          })}
        </div>
        <div className="mr-auto" style={{marginLeft:"auto", marginRight:"0 !important"}}>
          <a href="https://www.consumersenergy.com/residential/programs-and-services/electric-vehicles/home-charger-rebates/rebate-application" target="_blank" rel="noopener noreferrer">
            <button className="rate-change-button">Request a Rate Change</button>
          </a>
        </div>
      </div>
      {children}
    </div>
  );
};

export default RateComparisonChart;
