import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./VehicleCarousel.scss";

import EVCard from "../EVCard/EVCard";

const VehicleCarousel = ({ electricVehicles }) => {
  const [selectedEv, setSelectedEv] = useState(1);
  const [selectedCars, setSelectedCars] = useState([]);
  const firstEv = Math.floor(electricVehicles.length * Math.random());

  if (!electricVehicles || electricVehicles.length <= 3) return null;

  const vehicleCount = electricVehicles ? electricVehicles.length : "many";
  const maxIndex = vehicleCount - 1;

  const evIndices = [
    firstEv,
    firstEv + 1 <= maxIndex ? firstEv + 1 : firstEv + 1 - vehicleCount,
    firstEv + 2 <= maxIndex ? firstEv + 2 : firstEv + 2 - vehicleCount,
    firstEv + 3 <= maxIndex ? firstEv + 3 : firstEv + 3 - vehicleCount,
  ];

  if (selectedCars.length === 0) {
    setSelectedCars([
    electricVehicles[evIndices[0]],
    electricVehicles[evIndices[1]],
    electricVehicles[evIndices[2]],
    electricVehicles[evIndices[3]],
  ])}

  const selectableEvs = selectedCars.map((ev, i) => {
    return (
      <div
        onClick={() => setSelectedEv(i)}
        onKeyPress={() => setSelectedEv(i)}
        className={`${
          "evc-card EVCard " + (selectedEv === i ? " selected" : "")
        }`}
        tabIndex="0"
        key={i}
      >
        <EVCard
          ev={ev}
          hasLinkToEv={selectedEv === i}
          hasLocallyAvailableHidden
          hasEvDetails
        />
      </div>
    );
  });

  const renderVehicles = (
    <>
      <div className="row d-none d-lg-flex d-xl-flex">
        {/* <div className="col-sm-1 text-right">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px" }}
            onClick={() =>
              firstEv === 0 ? setFirstEv(maxIndex) : setFirstEv(firstEv - 1)
            }
          >
            &lsaquo;
          </button>
        </div> */}
        <div className="col-sm-12">
          <div className="render-cards-container full-width">
            {selectableEvs}
          </div>
        </div>
        {/* <div className="col-sm-1">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px" }}
            onClick={() =>
              firstEv === maxIndex ? setFirstEv(0) : setFirstEv(firstEv + 1)
            }
          >
            &rsaquo;
          </button>
        </div> */}
      </div>

      {/* <div className="render-cards-container full-width d-flex d-lg-none d-xl-none">
        {linkstoEvs}
      </div> */}
    </>
  );

  return (
    <section id="HomepageVehiclesCarousel">
      <div className="container">
        <div className="textContainer">
          <h2>There are more electric vehicles than ever. Discover yours.</h2>
          <p>
            Compare your selection to a similar gas vehicle. An EV can save you
            money because it can be cheaper to own and maintain than a 100%
            gasoline-powered car.
          </p>

          <div className="buttonRow">
            <Link to="/vehicles">
              <div className="button">Browse All EVs</div>
            </Link>
            <Link to="/compare-vehicles">
              <div className="button">Compare EVs</div>
            </Link>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-sm-12">{renderVehicles}</div>
        </div>
      </div>
    </section>
  );
};

export default VehicleCarousel;

VehicleCarousel.propTypes = {
  electricVehicles: PropTypes.array,
};
