import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import InputPanel from "../../components/InputPanel/InputPanel";
import RateComparisonChart from "../../components/RateComparisonChart/RateComparisonChart";
import rates from "../../data/rates";
// import SelectCurrentRate from '../../components/InputComponents/SelectCurrentRate/SelectCurrentRate';
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import SelectRateComparisonVehicle from "../../components/InputComponents/SelectRateComparisonVehicle/SelectRateComparisonVehicle";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import SlideMilesDrivenAnnually from "../../../components/InputComponents/SlideMilesDrivenAnnually/SlideMilesDrivenAnnually";
import SelectChargingPattern from "../../components/InputComponents/SelectChargingPattern/SelectChargingPattern";
import chargingPatterns from "../../data/chargingPatterns";
import isPHEV from "../../../functions/vehicle/isPHEV";
import SlideElectricMilesPortionForPhev from "../../../components/InputComponents/SlideElectricMilesPortionForPhev/SlideElectricMilesPortionForPhev";
import SlideCurrentMonthlyBill from "../../components/InputComponents/SlideCurrentMonthlyBill/SlideCurrentMonthlyBill";
// import GenericInputZipcode from '../../../components/InputComponents/InputZipcode/GenericInputZipcode';
// import SelectHasSolarAtHome from '../../components/InputComponents/SelectHasSolarAtHome/SelectHasSolarAtHome';
import SlideGasolinePrice from "../../../components/InputComponents/SlideGasolinePrice/SlideGasolinePrice";
import { FormatAsCents } from "../../../utils/Helpers/Format";
import SlideEquivalentMilesPerGallon from "../../components/InputComponents/SlideEquivalentMilesPerGallon/SlideEquivalentMilesPerGallon";
import SlidePublicChargingPercentage from "../../components/InputComponents/SlidePublicChargingPercentage/SlidePublicChargingPercentage";
import SelectPublicChargingCost from "../../components/InputComponents/SelectPublicChargingCost/SelectPublicChargingCost";
import getLoadProfileData from "../../data/loadProfiles/getLoadProfileData";
import zipcodeToBaselineAllocationRegion from "../../data/zipcodeToBaselineAllocationRegion.json";
import totalElectricVehicleKwhPerYear from "../../functions/totalElectricVehicleKwhPerYear";
import RateCostCalculator from "../../functions/RateCostCalculator";
import GasolineCostCalculator from "../../functions/GasolineCostCalculator";
import calculateRateTotals from "../../functions/calculateRateTotals";
import rateComparisonChartData from "../../functions/rateComparisonChartData";
import RateComparisonBanner from "../../components/RateComparisonBanner/RateComparisonBanner";
import RateDetails from "../../components/RateDetails/RateDetails";
import RateOptionsWizard from "../../components/RateOptionsWizard/RateOptionsWizard";

import "./RateComparison.scss";

const RateComparison = ({ electricVehicles, ip, uuid }) => {
  useEffect(() => {
    document.title =
      process.env.REACT_APP_PAGES_RATES_TITLE || "Electric Rate Advisor";
  });

  const userPrefs = useContext(UserPrefsContext);
  const {
    isComparingLowestRateOnly,
    vehicleIdForRateComparison,
    chargingPatternId,
    currentRateId,
    zipcode,
    hasSolarAtHome,
    milesDrivenAnnually,
    electricMilesPortionForPhev,
    publicChargingPercentage,
    publicChargingCostInCentsPerKwh,
    currentMonthlyBill,
    gasolinePriceInCentsPerGal,
    equivalentMilesPerGallon,
    selectedEnergyCostIds,
  } = userPrefs.get();

  const [selectedVehicle, setSelectedVehicle] = useState();

  useEffect(() => {
    console.log(
      "electricVehicles && vehicleIdForRateComparison",
      electricVehicles,
      vehicleIdForRateComparison
    );
    if (electricVehicles && vehicleIdForRateComparison) {
      setSelectedVehicle(
        electricVehicles.find(
          (ev) => ev.electric_vehicle_id === vehicleIdForRateComparison
        )
      );
    } else if (electricVehicles) {
      setSelectedVehicle(electricVehicles[0]);
    }
  }, [electricVehicles, vehicleIdForRateComparison]);

  const currentChargingPattern =
    chargingPatterns.find((p) => p.id === chargingPatternId) ||
    chargingPatterns[0];
  // Region 8 has the most customers according to SCE
  // NOTE: According to SCE's data, no one belongs to region 5
  const currentTerritory = zipcodeToBaselineAllocationRegion[zipcode] || "8";
  const currentRate = rates[currentRateId] || rates[Object.keys(rates)[0]];
  const evaluatedCurrentRate = {
    ...currentRate,
    data: currentRate.data(currentTerritory),
  };

  const homeLoadProfile = getLoadProfileData({
    rateGroup: currentRateId,
    climateZone: "MODERATE",
    hasSolar: hasSolarAtHome,
  });

  const totalElectricVehicleKwh = totalElectricVehicleKwhPerYear({
    kwhPer100Miles:
      selectedVehicle && selectedVehicle.electric_efficiency
        ? selectedVehicle.electric_efficiency
        : 0,
    milesDrivenAnnually,
    percentageDrivenElectric: isPHEV(selectedVehicle)
      ? electricMilesPortionForPhev
      : 100,
  });

  const homeChargingKwh =
    totalElectricVehicleKwh * ((100 - publicChargingPercentage) / 100);

  const publicChargingElectricCostPerMonth =
    (totalElectricVehicleKwh *
      (publicChargingCostInCentsPerKwh / 100) *
      (publicChargingPercentage / 100)) /
    12;

  const rateCostCalculator = new RateCostCalculator({
    typicalHomeLoadProfile: homeLoadProfile,
    currentRate: evaluatedCurrentRate,
    currentMonthlyBill,
    homeChargingKwh,
    chargingPatternLoadProfile: currentChargingPattern.loadProfile,
  });

  const gasolineCostCalculator = new GasolineCostCalculator({
    selectedVehicle,
    gasolinePriceInCentsPerGal,
    milesDrivenAnnually,
    electricMilesPortionForPhev,
    equivalentMilesPerGallon,
  });

  const evaluatedRates = Object.keys(rates).map((k) => ({
    ...rates[k],
    data: rates[k].data(currentTerritory),
  }));

  const rateTotals = calculateRateTotals({
    rates: evaluatedRates,
    currentRate: {
      ...currentRate,
      data: currentRate.data(currentTerritory),
    },
    rateCostCalculator,
    gasolineCostCalculator,
    publicChargingElectricCostPerMonth,
    selectedEnergyCostIds,
    isComparingLowestRateOnly,
  });

  const chartData = rateComparisonChartData({ rateTotals });

  return (
    <section className="container RateAdvisor">
      <div className="row">
        <div className="col-sm-12 title-area">
          <h1 style={{ textAlign: "center", fontSize: "30px" }}>
            <FormattedMessage
              id="rateAdvisor.title"
              defaultMessage="Rate Advisor"
              description="Rate Advisor"
            />
          </h1>
          <p
            className="lead"
            style={{
              textAlign: "center",
              maxWidth: "510px",
              margin: "0 auto 75px",
            }}
          >
            <FormattedMessage
              id="rateAdvisor.subTitle"
              defaultMessage="Identify the lowest cost electric rate for your EV. Adjust your information below to find the right electric rate for you."
              description="Rate Advisor Subtitle"
            />
          </p>
        </div>
      </div>

      {!electricVehicles ||
        (!selectedVehicle && (
          <div className="text-center">
            <LoadingSpinner />
          </div>
        ))}

      {electricVehicles && selectedVehicle && (
        <div className="row">
          <div className="col-md-3">
            <RateOptionsWizard
              electricVehicles={electricVehicles}
              rates={evaluatedRates}
              rateTotals={rateTotals}
              selectedVehicle={selectedVehicle}
              utilityName="SCE"
              typicalDriverName="Californian"
              mostCommonRateName={rates[Object.keys(rates)[0]].title}
            />
            <InputPanel
              title="Basic Filters"
              className="mb-4"
              defaultIsCollapsed={false}
            >
              <SelectRateComparisonVehicle
                electricVehicles={electricVehicles}
                selectedVehicle={selectedVehicle}
              />
              {/* <SelectCurrentRate rates={Object.keys(rates).map(key => rates[key])} /> */}
              <SlideMilesDrivenAnnually />
              <SelectChargingPattern chargingPatterns={chargingPatterns} />
            </InputPanel>
            <InputPanel
              title="Home Assumptions"
              className="mb-4"
              defaultIsCollapsed={false}
            >
              <SlideCurrentMonthlyBill id="monthly-slider-filters" />
              {/* <GenericInputZipcode buttonText="Update Zip Code" /> */}
              {/* <SelectHasSolarAtHome /> */}
              <p className="text-muted">
                The typical load profile changes dramatically if solar is
                installed.
              </p>
            </InputPanel>
            <InputPanel
              title="Driving Assumptions"
              className="mb-4"
              defaultIsCollapsed={false}
            >
              {isPHEV(selectedVehicle) && (
                <SlideElectricMilesPortionForPhev
                  label="Electric Portion"
                  description={(val) =>
                    `${val}% electric / ${100 - val} % gasoline`
                  }
                />
              )}
              <SlideGasolinePrice
                label="Gasoline Price"
                description={(val) => `${FormatAsCents(val / 100)} / gallon`}
              />
              <SlideEquivalentMilesPerGallon />
              <SlidePublicChargingPercentage />
              <SelectPublicChargingCost />
            </InputPanel>
          </div>
          <div className="col-md-9">
            <div className="rate-comparison-title">
              <h3>Rate Comparison</h3>
            </div>
            <RateComparisonBanner
              rateTotals={rateTotals}
              selectedVehicle={selectedVehicle}
            />
            <RateComparisonChart
              title="Rate Comparison"
              chartData={chartData}
              className="d-none d-md-block"
            ></RateComparisonChart>
            <div className="mt-4">
              <RateDetails
                rates={Object.keys(rates).map((k) => rates[k])}
                selectedRateDetailsId={userPrefs.get("selectedRateDetailsId")}
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default RateComparison;

RateComparison.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
