import React from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane } from "reactstrap";

import { ELECTRICIANS_TAB_ID } from "../../constants/mapTabs";
import ElectriciansMap from "../LocationMap/ElectriciansMap";

import money_icon from "../../client_customizations/assets/images/icons/money-icon.svg";
import customer_icon from "../../client_customizations/assets/images/icons/customer-icon.svg";
import users_icon from "../../client_customizations/assets/images/icons/users-icon.svg";
import installation_icon from "../../client_customizations/assets/images/icons/installation-icon.svg";


import "./ElectricianTabbedMaps.scss";
import { FormattedMessage } from "react-intl";

const TabbedMaps = ({ activeTabId, userLocation, zipcode }) => {


  const size = window.innerWidth > 600;
  return (
    <div className="ElectricianTabbedMaps">
      <div className="container">
        <div className="titleContainer">
          <p className="electricianTitle">Find an Electrician</p>
          <p className="electricianSubtitle">
            Installing an EV charger requires a certified electrician. Click the
            map below for the names and contact information of qualified
            electricians near you.
          </p>
        </div>

        <div className="map-header-title" style={{fontSize:"20px", paddingLeft:"10px"}}>
          <FormattedMessage
            id="electricians.map.header.alt"
            defaultMessage="Our Preferred Partners"
            description="Our Preferred Partners"
          />
        </div>
        <div className="map-header-subtitle" style={{paddingLeft:"10px"}}>
              <p style={{fontSize:"15px"}}>Preferred partners are not required to be used to qualify for the PowerMIDrive rebate pending all terms and conditions are met for installation of a Residential Home UL-Certified, Energy Star rated Level 2 charger or OEM-manufactured charger (capable of Level 2 charging), with up to 9.6kW of output (up to 50AMP circuit)</p>
        </div>
        <div className="electricians-table-container">
              <table>
              <tr className="">
              <th className="left">
                     <FormattedMessage
                     id="Preferredpartner"
                     defaultMessage="Preferred Partner"
                     description="Preferred Partner"
                     />
              </th>
              <th>
                     <FormattedMessage
                     id="CountiesCovered"
                     defaultMessage="Counties Covered"
                     description="Counties Covered"
                     />
              </th>
              <th>
                     <FormattedMessage
                     id="ContactInformation"
                     defaultMessage="Contact Information"
                     description="Contact Information"
                     />
              </th>
              <th className="right">
                     <FormattedMessage
                     id="Website"
                     defaultMessage="Website"
                     description="Website"
                     />
              </th>
              </tr>
              <tr className="white">
              <td>State Electric Company</td>
              <td>All Counties</td>
              <td>
                     <a href="tel: +1-866-976-8890">866-976-8890</a>
              </td>
              <td>
                     {size ? (
                     <a href="http://www.stateelectriccompany.net">
                     http://www.stateelectriccompany.net
                     </a>
                     ) : (
                     <a href="http://www.stateelectriccompany.net">link</a>
                     )}
              </td>
              </tr>
              <tr>
              <td>Income Power</td>
              <td>All Counties</td>
              <td>
                     <a href="tel: +1-248-592-3232">248-592-3232</a>
              </td>
              <td>
                     {size ? (
                     <a href="http://www.incomepowerllc.com/">
                     www.incomepowerllc.com/
                     </a>
                     ) : (
                     <a href="http://www.incomepowerllc.com/">link</a>
                     )}
              </td>
              </tr>
              <tr className="white">
              <td>McWire Electric</td>
              <td>Livingston, Macomb, Oakland, St. Clair, Wayne</td>
              <td>
                     <a href="tel: +1-586-563-3539">586-563-3539</a>
              </td>
              <td>
                     {size ? (
                     <a href="https://www.mcwireelectric.com/">
                     www.mcwireelectric.com/
                     </a>
                     ) : (
                     <a href="https://www.mcwireelectric.com/">link</a>
                     )}
              </td>
              </tr>
              </table>
        </div>

       <div className="electrician-benefits-container" style={{marginTop: "50px"}}>
              <h2 className="benefitsTitle" style={{fontSize: "20px"}}>Benefits of our Preferred Partners</h2>
              <ul>
                     <li>
                            <img src={money_icon} alt="Money Icon" />
                            <h4>
                                   <FormattedMessage
                                          id="electricians.benefits.value.header"
                                          defaultMessage="A Great Value"
                                          description="A Great Value"
                                   />
                            </h4>
                            <FormattedMessage
                                   id="electricians.benefits.value"
                                   defaultMessage="Our installation costs are some of the lowest in the state."
                                   description="Our installation costs are some of the lowest in the state."
                            />
                     </li>
                     <li>
                            <img src={customer_icon} alt="Customer Icon" />
                            <h4>
                                   <FormattedMessage
                                          id="electricians.benefits.help.header"
                                          defaultMessage="One-on-One Help"
                                          description="One-on-One Help"
                                   />
                            </h4>
                            <FormattedMessage
                                   id="electricians.benefits.help"
                                   defaultMessage="EV Specialists are available to answer any of your EV related questions."
                                   description="EV Specialists are available to answer any of your EV related questions."
                            />
                     </li>
                     <li>
                            <img src={users_icon} alt="users icon" />
                            <h4>
                                   <FormattedMessage
                                          id="electricians.benefits.hometown.header"
                                          defaultMessage="Our Hometown Service"
                                          description="Our Hometown Service"
                                   />
                            </h4>
                            <FormattedMessage
                                   id="electricians.benefits.hometown"
                                   defaultMessage="Our electricians will provide a professional installation."
                                   description="Our electricians will provide a professional installation."
                            />
                     </li>
                     <li>
                            <img src={installation_icon} alt="installation icon"/>
                            <h4>
                                   <FormattedMessage
                                          id="electricians.benefits.installation.header"
                                          defaultMessage="Fast & Easy Installation"
                                          description="Fast & Easy Installation"
                                   />
                            </h4>
                            <FormattedMessage
                                   id="electricians.benefits.installation"
                                   defaultMessage="We’ll make sure to get you set up for charging quickly."
                                   description="We’ll make sure to get you set up for charging quickly."
                            />
                     </li>
              </ul>
       </div>

       <div className="electrician-get-started-container">
              <h3>$500 Off Installation Costs for a Qualified Level 2 Charger</h3>
              <a href="https://www.consumersenergy.com/residential/programs-and-services/electric-vehicles/home-charger-rebates" rel="noopener noreferrer" target="_blank" className="btn-ae btn-default mb-2 btn-block">Get Started</a>
              <p className="disclaimer">*Note: Preferred partners cannot complete any CE rebate forms for charger installation on your behalf</p>
       </div>

       <div class="electricans-map-container" style={{marginTop: "50px"}}>
       <h2 className="electricianTitle" style={{fontSize: "20px", paddingLeft: "10px"}}>Electrician Map</h2>
        <div className="tabbed-maps-panel">
          <TabContent activeTab={activeTabId}>
            <TabPane tabId={ELECTRICIANS_TAB_ID}>
              <ElectriciansMap
                userLocation={userLocation}
                zipcode={zipcode}
                isVisible={activeTabId === ELECTRICIANS_TAB_ID}
              />
            </TabPane>
          </TabContent>
        </div>
       </div>
      </div>
    </div>
  );};

TabbedMaps.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  electricVehicles: PropTypes.array,
  oems: PropTypes.array,
  activeTabId: PropTypes.string,
  toggle: PropTypes.func,
  canIgnoreLowPowerStations: PropTypes.bool,
};

export default TabbedMaps;