import React from "react";
import PropTypes from "prop-types";

import ChargingPinHigh from "./../../../client_customizations/assets/images/icons/PreferredMark.svg";
import ChargingPinPublic from "./../../../client_customizations/assets/images/icons/RegularMark.svg";

import "./ElectricianChargingStationsMapLegend.scss";

const ElectricianChargingStationsMapLegend = () => {
  return (
    <div className="ElectricianChargingStationsMapLegend">
      <div className="charger-type">
        <img src={ChargingPinPublic} alt="" />
        <b>Preferred partners</b>
      </div>

      <div className="charger-type">
        <img src={ChargingPinHigh} alt="" />
        <b>Other electricians</b>
      </div>
    </div>
  );
};

ElectricianChargingStationsMapLegend.propTypes = {
  chargingStations: PropTypes.array,
};

ElectricianChargingStationsMapLegend.defaultProps = {
  chargingStations: [],
};

export default ElectricianChargingStationsMapLegend;
