import React from "react";
import PropTypes from "prop-types";
import IncentiveCatalog from "./../../components/IncentiveCatalog/IncentiveCatalog";
import IncentivePreferences from "./../../client_customizations/components/IncentivePreferences/IncentivePreferences";
import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import { FormattedMessage, useIntl } from "react-intl";

import "./Incentives.scss";
const Incentives = ({ electricVehicles, incentives, location, ip, uuid }) => {
  const intl = useIntl();
  const renderIncentives = incentives ? (
    <IncentiveCatalog
      location={location}
      incentives={incentives}
      category="all"
    />
  ) : (
    <LoadingSpinner />
  );

  return (
    <>
      <section className="container IncentivesPage">
        <div className="row">
          <div className="col-sm-12">
            <h1>
              <FormattedMessage
                id="incentives.title"
                defaultMessage="Electric Vehicle and Charger Incentives"
                description="Electric Vehicle and Charger Incentives"
              />
            </h1>
            <p className="lead subtitle">
              You may be eligible for a range of incentives, including EV
              rebates, EV tax credits and various other benefits.
            </p>
            <IncentivePreferences
              incentives={incentives}
              electricVehicles={electricVehicles}
              location={location}
              titleText={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "personalizeIncentives",
                      defaultMessage: "Personalize Incentives",
                    })
                  : "Personalize Incentives"
              }
              btnText={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "personalizeIncentives",
                      defaultMessage: "Personalize Incentives",
                    })
                  : "Personalize Incentives"
              }
            />
          </div>
        </div>
        <br />
        {renderIncentives}
        <br />
      </section>
    </>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.object,
  electricVehicles: PropTypes.array,
  location: PropTypes.object,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
