import React from "react";
import PropTypes from "prop-types";

import sortIncentives from "../../functions/incentives/Sort/sortIncentives";
import IncentiveCatalog from "../IncentiveCatalog/IncentiveCatalog";
import { FormatAsDollars, FormatCarName } from "./../../utils/Helpers/Format";
import { FormattedMessage } from 'react-intl';

const EVIncentives = ({ car, incentives, usedEv, location }) => {
  if (!incentives) return null;

  incentives = usedEv ? incentives.filter(incentive => incentive.applicable_to_used_vehicles) : incentives

  const totalSavings = sortIncentives(incentives).total("eligible-rebates");
  return (
    <section className="container-fluid" id="EVIncentives">
      <section className="container">
        <div className="EVIncentives">
          <div className="row">
            <div className="col-12">
              <p className="h1 text-center bottom-margin">
                {usedEv ? (
                  <FormattedMessage
                    id="ev.incentives.title.simple"
                    defaultMessage="Up to {savings} in tax credits and rebates are potentially available {lineBreak} for the {car}"
                    description="EV Incentives Title"
                  />
                ) : (
                  <FormattedMessage
                    id="ev.incentives.title"
                    defaultMessage="Up to {savings} in tax credits and rebates are potentially available {lineBreak} for the {car}"
                    description="EV Incentives Title"
                    values={{
                      savings: (
                        <strong style={{ color: "rgb(33, 135, 57)" }}>
                          {FormatAsDollars(totalSavings)}
                        </strong>
                      ),
                      lineBreak: <br />,
                      car: FormatCarName(car),
                    }}
                  />
                )}
              </p>
              <IncentiveCatalog incentives={incentives} usedEv={usedEv} />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default EVIncentives;

EVIncentives.propTypes = {
  car: PropTypes.object,
  incentives: PropTypes.array
};
