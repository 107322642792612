import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import fetchElectricians from "../../services/fetchElectricians";
import Select from "../../components/shared/InputElements/Select";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import GenericInputZipcode from "../../components/InputComponents/InputZipcode/GenericInputZipcode";
import SelectedCard from "../../components/SelectedCard/SelectedCard";
import RegularMark from "../../client_customizations/assets/images/icons/RegularMark.svg";
import PreferredMark from "../../client_customizations/assets/images/icons/PreferredMark.svg";
import BaseGoogleMapReact from "./BaseGoogleMapReact/BaseGoogleMapReact";
import ElectricianChargingStationsMapLegend from "./ElectricianChargingStationsMapLegend/ElectricianChargingStationsMapLegend";

const ElectricianMarker = ({
  isSelected,
  onClick,
  onMouseOver,
  electrician,
}) => {
  
  const styles = {
    width: "25px",
    ...(isSelected && {
      filter: "brightness(85%)",
    }),
  };

  const placeStyle = {
    display: "block",
    position: "absolute",
    transform: "translate(-50%, -100%)",
  };

  const partnerList = [
    "State Electric Company",
    "Income Power",
    "McWire Electric",
  ];

  let isPartner = false;

  if (partnerList.includes(electrician.name)) {
    isPartner = true;
  }
  
  return (
    <span style={placeStyle} onClick={onClick} onMouseOver={onMouseOver}>
      {isPartner ? (
        <img src={RegularMark} style={styles} alt="" />
      ) : (
        <img src={PreferredMark} style={styles} alt="" />
      )}
    </span>
  );
};

const ElectriciansMap = ({ userLocation, zipcode, ip, uuid, isVisible }) => {
  const [selectedElectricianName, setSelectedElectricianName] =
    useState(undefined);
  const [electricianType, setElectricianType] = useState("All");
  const [electricians, setElectricians] = useState([]);
  const [center, setCenter] = useState();
  const intl = useIntl();

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      const params = {
        postcode: zipcode,
        distance: 200,
      };
      try {
        const electricians = await fetchElectricians(params);
        if (!ignore) setElectricians(electricians);
      } catch (e) {
        console.log("Failed to load electricians");
      }
    }

    fetchData();

    return () => {
      ignore = true;
    };
  }, [zipcode]);

  useEffect(() => {
    if (!userLocation) return;
    setCenter({
      lat: parseFloat(userLocation.latitude),
      lng: parseFloat(userLocation.longitude),
    });
  }, [userLocation]);

  const findElectrician = () =>
    (electricians || []).find(
      (electrician) => electrician.name === selectedElectricianName
    );

  // const deselectElectrician = () => setSelectedElectricianName(undefined);

  const filteredElectricians = (electricians || []).filter((electrician) => {
    if (electricianType === "All") {
      return true;
    }
    return electrician[electricianType.toLowerCase()] === 1;
  });

  const onMapChange = (obj) => {
    console.log(obj);
  };

  const SelectElectricianType = () => {
    return (
      <div className="form-group">
        <Select
          id="project-type"
          value={electricianType}
          handler={(e) => setElectricianType(e.target.value)}
          label="Project Type"
          optionNames={[
            intl.formatMessage({ id: "all", defaultMessage: "All" }),
            intl.formatMessage({
              id: "electricians.residential",
              defaultMessage: "Residential",
            }),
            intl.formatMessage({
              id: "electricians.commercial",
              defaultMessage: "Commercial",
            }),
            intl.formatMessage({
              id: "electricians.industrial",
              defaultMessage: "Industrial",
            }),
          ]}
          optionValues={["All", "Residential", "Commercial", "Industrial"]}
        />
      </div>
    );
  };

  const body = !userLocation ? (
    <div className="spinner-container">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <div
        style={{ display: "flex", flexDirection: "column" }}
        className="col-sm-3 left-column"
      >
        <div className="controls">
          <GenericInputZipcode
            buttonText="Update zip code"
            SelectElectricianType={SelectElectricianType}
          />
        </div>
        <ElectricianChargingStationsMapLegend />
      </div>
      <div className="col-sm-9">
        <div className="map-container">
          <div className="sidebar-container">
            {findElectrician() && (
              <div className="selected-dealer-container">
                <SelectedCard {...findElectrician()} />
              </div>
            )}
          </div>
          {isVisible && (
            <BaseGoogleMapReact center={center} zoom={9} onChange={onMapChange}>
              {filteredElectricians.map((electrician) => (
                <ElectricianMarker
                  electrician={electrician}
                  key={electrician.name}
                  lat={electrician.latitude}
                  lng={electrician.longitude}
                  isSelected={electrician.name === selectedElectricianName}
                  onMouseOver={() => {
                    setSelectedElectricianName(electrician.name);
                  }}
                  onClick={() => {
                    setSelectedElectricianName(electrician.name);
                  }}
                />
              ))}
            </BaseGoogleMapReact>
          )}
        </div>
      </div>
    </>
  );

  return (
    <div className="DealerMap">
      <div style={{ height: "100%" }} className="row dealerMap-content">
        {body}
      </div>
    </div>
  );
};

export default ElectriciansMap;

ElectriciansMap.propTypes = {
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
