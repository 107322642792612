import "chartjs-plugin-datalabels";

import { FormatAsDollars } from "../../../utils/Helpers/Format";

const chartOptions = ({
  shouldShowSavingsAmount,
  currentTotal = 0,
  savingsColor = "#000000",
}) => {
  return {
    maintainAspectRatio: true,
    responsive: true,
    tooltips: {
      mode: "index",
      callbacks: {
        label: function (tooltipItem, data) {
          const value = FormatAsDollars(
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          );
          const label = data.datasets[tooltipItem.datasetIndex].label || "";
          return ` ${label}: ${value}`;
        },
        title: function (tooltipItems, data) {
          const total = tooltipItems.reduce(function (acc, item) {
            return acc + item.yLabel;
          }, 0);
          return `${tooltipItems[0].xLabel.join(" ")}: ${FormatAsDollars(
            total
          )}`;
        },
      },
    },
    legend: {
      reverse: true,
      position: "bottom",
      onClick: function (e) {
        e.stopPropagation();
      },
      labels: {
        fontSize: 14,
        fontColor: "#262626",
        padding: 20,
        fontFamily: "'Open Sans'",
        boxWidth: 20,
      },
    },
    layout: {
      padding: 50,
    },
    scales: {
      yAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            display:false,
            drawTicks: false,
            color: "rgba(0, 0, 0, 0.03)",
          },
          ticks: {
            callback: function (value) {
              return `$${value.toFixed(0)}`;
            },
            fontSize: 14,
            fontColor: "#000000",
            fontFamily: "'Open Sans'",
            padding: 15,
            maxTicksLimit: 6,
          },
          scaleLabel: {
            display: true,
            labelString: "Estimated average costs per year",
            fontSize: 14,
            fontWeight: 800,
            fontColor: "#262626",
            fontFamily: "'Open Sans'",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: true,
          barThickness: 120,
          categorySpacing:30,
          ticks: {
            fontSize: 14,
            fontWeight: 800,
            fontColor: "#262626",
            fontFamily: "'Open Sans'",
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          if (ctx.datasetIndex !== ctx.chart.data.datasets.length - 1)
            return "";

          // const currentAnnualAmount = ctx.chart.data.datasets.reduce(
          //   (total, dataset) => total + dataset.data[0],
          //   0
          // );
          const annualAmountForRate = ctx.chart.data.datasets.reduce(
            (total, dataset) => total + dataset.data[ctx.dataIndex],
            0
          );

          // if (ctx.dataIndex === 0)
          //   return `${FormatAsDollars(currentAnnualAmount)} / yr`;
          // if (annualAmountForRate < currentAnnualAmount) {
          //   // return `Save ${FormatAsDollars(currentAnnualAmount - annualAmountForRate)} / yr`;
          // }
          // if (annualAmountForRate > currentAnnualAmount) {
          //   // return `Lose ${FormatAsDollars(annualAmountForRate - currentAnnualAmount)} / yr`;
          // }
          return `${FormatAsDollars(annualAmountForRate.toFixed(0))} / yr`;
        },
        align: "end",
        anchor: "end",
        color: (ctx) => {
          // if (ctx.datasetIndex !== ctx.chart.data.datasets.length - 1)
          //   return "#000000";

          // const currentMonthlyAmount = ctx.chart.data.datasets.reduce(
          //   (total, dataset) => total + dataset.data[0],
          //   0
          // );
          // const monthlyAmountForRate = ctx.chart.data.datasets.reduce(
          //   (total, dataset) => total + dataset.data[ctx.dataIndex],
          //   0
          // );

          // if (monthlyAmountForRate < currentMonthlyAmount) return "#00664F";
          return "#000000";
        },
        font: {
          weight: 700,
          size: 18,
        },
      },
    },
  };
};

export default chartOptions;
