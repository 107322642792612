import { times } from "lodash";
import {
  FROM_11PM_TO_6AM,
  FROM_2PM_TO_7PM,
  FROM_6AM_TO_2PM_AND_7PM_TO_11PM,
} from "../constants/";
import from11pmto6am from "./chargingPatterns/FROM_11PM_TO_6AM.svg";
import from6pmto2pmand7pmto11pm from "./chargingPatterns/FROM_6AM_TO_2PM_AND_7PM_TO_11PM.svg";
import from2pmto7pm from "./chargingPatterns/FROM_2PM_TO_7PM.svg";
// import allHoursImage from "./chargingPatterns/all-hours.svg";

const DST = {
  2021: {
    startHour: 1610,
    endHour: 7322,
  },
};

const dstAdjustedHour = (hour, year) => {
  const { startHour, endHour } = DST[year];
  const isWithinDST = hour >= startHour && hour <= endHour;
  return isWithinDST ? (hour + 1) % 24 : hour % 24;
};

// NOTE: this is not a complete list of leap years, but is sufficient for the data
// provided
const isLeapYear = (year) => [2016, 2020].includes(year);
const hoursInYear = (year) => (isLeapYear(year) ? 8784 : 8760);

const chargingPatterns = [
  {
    id: FROM_11PM_TO_6AM,
    title: "Overnight: 11pm to 6am",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const hour = dstAdjustedHour(i, year);
          return hour >= 23 || hour < 5 ? 1.1 : 0;
        }),
    },
    image: from11pmto6am,
    description: "Recommended!",
  },
  {
    id: FROM_6AM_TO_2PM_AND_7PM_TO_11PM,
    title: "6am to 2pm; 7pm to 11pm",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const hour = dstAdjustedHour(i, year);
          return hour >= 6 || hour < 14 || hour >= 19 || hour < 23 ? 1.1 : 0;
        }),
    },
    image: from6pmto2pmand7pmto11pm,
  },
  {
    id: FROM_2PM_TO_7PM,
    title: "2pm to 7pm",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const hour = dstAdjustedHour(i, year);
          return hour >= 14 || hour < 19 ? 1.1 : 0;
        }),
    },
    image: from2pmto7pm,
  },
  // {
  //   id: ALL_HOURS,
  //   title: "I'm Flexible (All Hours)",
  //   loadProfile: {
  //     data: () => times(hoursInYear(2021), (_) => 1.1),
  //   },
  //   image: allHoursImage,
  // },
];

export default chargingPatterns;
