import times from "lodash/times";
import {HOLIDAYS} from "./holidays";

// https://library.sce.com/content/dam/sce-doclib/public/regulatory/tariff/electric/schedules/residential-rates/ELECTRIC_SCHEDULES_D.pdf
// The Tiered charges are the same for winter and summer, so they don't technically
// need to be split into 6 separate tiers.
// However, the rate tariff defines them that way, so presumably they may change at
// some point.

const createRateComponent = (months, charge, hours, season) => ({
  charge: charge,
  months: months,
  daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
  hourStarts: hours,
  exceptForDays: HOLIDAYS,
  name: season,
});

const createAllRateComponents = () => {
  const winterRateComponents1 = createRateComponent(
    [1, 2, 3, 4, 5, 10, 11, 12], //months
    0.142, //chargers
    [...times(6, Number), 23], //hours
    "Winter" //season
  );
  const winterRateComponents2 = createRateComponent(
    [1, 2, 3, 4, 5, 10, 11, 12],
    0.168,
    [...times(8, (i) => Number(i + 6)), ...times(4, (i) => Number(i + 19))],
    "Winter"
  );
  const winterRateComponents3 = createRateComponent(
    [1, 2, 3, 4, 5, 10, 11, 12],
    0.17,
    times(5, (i) => Number(i + 14)),
    "Winter"
  );
  const summerRateComponents1 = createRateComponent(
    [6, 7, 8, 9],
    0.145,
    [...times(6, Number), 23],
    "Summer"
  );
  const summerRateComponents2 = createRateComponent(
    [6, 7, 8, 9],
    0.223,
    [...times(8, (i) => Number(i + 6)), ...times(4, (i) => Number(i + 19))],
    "Summer"
  );
  const summerRateComponents3 = createRateComponent(
    [6, 7, 8, 9],
    0.18,
    times(5, (i) => Number(i + 14)),
    "Summer"
  );

  return [winterRateComponents1, winterRateComponents2, winterRateComponents3, summerRateComponents1, summerRateComponents2, summerRateComponents3];
};

const scheduleD = (territory) => {
  return {
    name: "TOU-D-4to9",
    title: "TOU-D-4to9",
    rateElements: [
      {
        name: "Energy Charges",
        rateElementType: "EnergyTimeOfUse",
        rateComponents: createAllRateComponents(),
      },
    ],
  };
};

export default scheduleD;

