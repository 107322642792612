import React from "react";
import PropTypes from "prop-types";

import "./SelectedCard.scss";
import LinkIcon from "../../client_customizations/assets/images/icons/icn_link.svg";

import GaTracker from "../../utils/GaTracker/GaTracker";
import { FormattedMessage } from "react-intl";

const SelectedCard = ({
  name,
  street,
  city,
  region,
  postcode,
  phone,
  oem,
  website,
  oemImage,
}) => {
  const secondLineAddress = [city, region, postcode]
    .filter((el) => el)
    .join(", ");

  const handleClickWebsite = () => {
    GaTracker.trackEvent({
      category: "Dealers",
      action: "Clicked Website Link",
      label: name,
    });
  };

  const handleClickPhone = () => {
    GaTracker.trackEvent({
      category: "Dealers",
      action: "Clicked Phone Link",
      label: name,
    });
  };

  return (
    <div className="SelectedCard">
      {oemImage && (
        <img
          src={`https://zappyassets.s3.amazonaws.com/img/oems/${oem}.png`}
          alt={oemImage.name}
          className="oem-image"
        />
      )}
      <h5>{name || ""}</h5>
      <p className="address-container">
        {street || ""}
        <br />
        {secondLineAddress || ""}
        <br />
        {phone && (
          <div className="contact-dealer">
            <a href={`tel: ${phone}`} onClick={handleClickPhone}>
              {phone}
            </a>
          </div>
        )}
      </p>
      <div className="links-container">
        <div className="row">
          {website && (
            <div className="website-dealer">
              <img src={LinkIcon} alt="" />
              &nbsp;
              <a
                href={website}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleClickWebsite}
              >
                <FormattedMessage
                  id="dealers.website"
                  defaultMessage="WEBSITE"
                  description="Dealer Website"
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SelectedCard.propTypes = {
  name: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  region: PropTypes.string,
  postcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  phone: PropTypes.string,
  website: PropTypes.string,
  oemImage: PropTypes.bool,
};

export default SelectedCard;
