import {
  HOME_ENERGY_COST_ID,
  EV_ENERGY_COST_ID,
  GAS_ENERGY_COST_ID
} from '../constants/';

const rateComparisonChartData = ({ rateTotals }) => {
  return {
    datasets: [
      {
        label: "Home",
        id: HOME_ENERGY_COST_ID,
        data: rateTotals.map((rateTotal) => rateTotal[HOME_ENERGY_COST_ID]),
        backgroundColor: "#005db4",
        barPercentage: 0.5,
        categoryPercentage: 1,
      },
      {
        label: "Electric Fuel",
        id: EV_ENERGY_COST_ID,
        data: rateTotals.map((rateTotal) => rateTotal[EV_ENERGY_COST_ID]),
        backgroundColor: "#8ebb37",
        barPercentage: 0.5,
        categoryPercentage: 1,
      },
      {
        label: "Gasoline Fuel",
        id: GAS_ENERGY_COST_ID,
        data: rateTotals.map((rateTotal) => rateTotal[GAS_ENERGY_COST_ID]),
        backgroundColor: "#faa61a",
        barPercentage: 0.5,
        categoryPercentage: 1,
      },
      
    ],
    labels: [
      ["Current", rateTotals[0].title],
      ...rateTotals.filter((rate, i) => i !== 0).map((rate) => rate.title),
    ],
  }
}

export default rateComparisonChartData;
