import React from 'react';

const TouD5to8pmDetails = () => {
  return (
    <div>
      <h2>Rate Plan: Residential Smart Hours</h2>

      <p>If you don't mind managing your electricity use throughout the year, you get a lower rate during off-peak times that could lower your bill.</p>

      <h2>How it works:</h2>

      <p>Take advantage of a lower off-peak rate by shifting your electricity use away from peak hours:</p>

      <p><strong>June - September:</strong> Weekdays, from 2 to 7 p.m. when electricity will cost about five cents more per kilowatt hour, compared to off-peak times.</p>

      <p><strong>October - May:</strong> Weekdays, from 2 to 7 p.m. when electricity will cost about one cent more per kilowatt hour, compared to off-peak times.</p>

      <h2>What it means for you:</h2>

      <p>If you're not at home during the day or able to shift electric use outside of on-peak times, this could be a good rate for you.</p>

      <a href="https://www.consumersenergy.com/residential/rates/electric-rates-and-programs/rate-plan-options/smart-hours" rel="noopener noreferrer" target="_blank"><button className='details-button'>Learn More</button></a>
    </div>
  );
};

export default TouD5to8pmDetails;
