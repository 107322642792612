import React, { useContext } from 'react';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';

const RateDetails = ({ rates }) => {
  const userPrefs = useContext(UserPrefsContext);
  const {
    selectedRateDetailsId
  } = userPrefs.get();

  const selectedRate = rates.find(rate => rate.id === selectedRateDetailsId);
  const SelectedDetailsComponent = (selectedRate || {}).detailsComponent ||
    (() => null);

  return (
    <div className="chart-details-container">
        <div>
          <div className="d-flex mb-4">
            {rates.map(rate => {
              return (
                <button
                  className={`btn btn-${rate.id === selectedRateDetailsId ? "ae" : "secondary"} mr-2`}
                  key={rate.id}
                  onClick={() => userPrefs.set({ selectedRateDetailsId: rate.id })}
                >
                  {rate.title} {rate.subTitle}
                </button>
              )
            })}
          </div>
          <div>
            <SelectedDetailsComponent />
          </div>
        </div>
    </div>
  );
};

export default RateDetails;
