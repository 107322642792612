import React from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import {
  ALL_STATIONS_TAB_ID,
  MAP_TABS,
  CHARGERS_CATALOG_TAB_ID,
  ELECTRICIANS_TAB_ID,
  ROUTE_MAP_TAB_ID,
} from "../../constants/mapTabs";
import ChargingMap from "../LocationMap/ChargingMap";
import DealerMap from "../LocationMap/DealerMap";
import ElectriciansMap from "../LocationMap/ElectriciansMap";
import TravelRouteMap from "../LocationMap/TravelRouteMap/TravelRouteMap";

import "./TabbedMaps.scss";
import { FormattedMessage } from "react-intl";


const activeClass = (tabNumber, activeTab) =>
  activeTab === tabNumber ? "active" : "";

const TabbedMaps = ({
  chargingStationsFilterFn,
  electricVehicles,
  oems,
  activeTabId,
  toggle,
  canIgnoreLowPowerStations = true,
  userLocation,
  dealerLocations,
  zipcode,
}) => (
  <div className="TabbedMaps">
    <div className="form-group d-md-none">
      <select
        value={activeTabId}
        onChange={(e) => toggle(e.target.value)}
        className="form-control"
      >
        {MAP_TABS.map((tab) => (
          <option key={tab.name} value={tab.id}>
            {tab.name}
          </option>
        ))}
      </select>
    </div>
    <div className="container">
      {electricVehicles ? null : (
        <div className="titleContainer">
          {activeTabId === "tabbed-maps-all-stations-tab" ? (
            <>
              <h2 className="title">Locate Charging Stations</h2>
              <p className="subtitle">
                <FormattedMessage
                  id="ev.chargingStations.body"
                  defaultMessage="Charging stations Body"
                  description="Charging stations Body"
                  values={{
                    lineBreak: <br />,
                  }}
                />
              </p>
            </>
          ) : (
            <>
              <h2 className="title">Plan Your Route</h2>
              <p className="subtitle">
                <FormattedMessage
                  id="ev.planningRoute.body"
                  defaultMessage="Plan Route Body"
                  description="Plan Route Body"
                  values={{
                    lineBreak: <br />,
                  }}
                />
              </p>
            </>
          )}
        </div>
      )}
      <div className="tabbed-maps-panel">
        <Nav tabs className="d-none d-md-flex">
          {MAP_TABS.map((tab) => (
            <NavItem key={tab.name}>
              <NavLink
                className={activeClass(tab.id, activeTabId)}
                onClick={() => {
                  toggle(tab.id);
                }}
              >
                {tab.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTabId}>
          {process.env.REACT_APP_PAGES_CHARGING_ENABLED && (
            <TabPane tabId={ALL_STATIONS_TAB_ID}>
              <ChargingMap
                userLocation={userLocation}
                isVisible={activeTabId === ALL_STATIONS_TAB_ID}
              />
            </TabPane>
          )}
          {process.env.REACT_APP_PAGES_MAP_ROUTE_ENABLED && (
            <TabPane tabId={ROUTE_MAP_TAB_ID}>
              <TravelRouteMap isVisible={activeTabId === ROUTE_MAP_TAB_ID} />
            </TabPane>
          )}
          {process.env.REACT_APP_PAGES_DEALERS_ENABLED && (
            <TabPane tabId={CHARGERS_CATALOG_TAB_ID}>
              <DealerMap
                userLocation={userLocation}
                dealerLocations={dealerLocations}
                isVisible={activeTabId === CHARGERS_CATALOG_TAB_ID}
              />
            </TabPane>
          )}
          {process.env.REACT_APP_PAGES_ELECTRICIANS_ENABLED && (
            <TabPane tabId={ELECTRICIANS_TAB_ID}>
              <ElectriciansMap
                userLocation={userLocation}
                zipcode={zipcode}
                isVisible={activeTabId === ELECTRICIANS_TAB_ID}
              />
            </TabPane>
          )}
        </TabContent>
      </div>

      {activeTabId === ELECTRICIANS_TAB_ID && (
        <>
          <div className="tableHeader">
            <p>
              <FormattedMessage
                id="Preferredpartner"
                defaultMessage="Preferred Partner"
                description="Preferred Partner"
              />
            </p>
            <p>
              <FormattedMessage
                id="CountiesCovered"
                defaultMessage="Counties Covered"
                description="Counties Covered"
              />
            </p>
            <p>
              <FormattedMessage
                id="ContactInformation"
                defaultMessage="Contact Information"
                description="Contact Information"
              />
            </p>
            <p>
              <FormattedMessage
                id="Website"
                defaultMessage="Website"
                description="Website"
              />
            </p>
          </div>
          <div className="tableRow">
            <p>Vehya</p>
            <p>All Counties</p>
            <a href="tel: +1-586-697-0095">586-697-0095</a>
            <a href="https://www.vehya.com/">www.vehya.com/</a>
          </div>
          <div className="tableRow gray">
            <p>State Electric Company</p>
            <p>All Counties</p>
            <a href="tel: +1-866-976-8890">866-976-8890</a>
            <a href="www.stateelectriccompany.net">
              www.stateelectriccompany.net
            </a>
          </div>
          <div className="tableRow">
            <p>Myarc Charging Solutions</p>
            <p>All Counties</p>
            <a href="tel: +1-734-620-7301">734-620-7301</a>
            <a href="www.myarccharging.com">www.myarccharging.com</a>
          </div>
          <div className="tableRow gray">
            <p>Income Power</p>
            <p>All Counties</p>
            <a href="tel: +1-808-866-2118">808-866-2118</a>
            <a href="http://www.incomepowerllc.com/">www.incomepowerllc.com/</a>
          </div>
          <div className="tableRow">
            <p>McWire Electric</p>
            <p>Livingston, Macomb, Oakland, St. Clair, Wayne</p>
            <a href="tel: +1-586-563-3539">586-563-3539</a>
            <a href="https://www.mcwireelectric.com/">
              www.mcwireelectric.com/
            </a>
          </div>
        </>
      )}
    </div>
  </div>
);

TabbedMaps.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  electricVehicles: PropTypes.array,
  oems: PropTypes.array,
  activeTabId: PropTypes.string,
  toggle: PropTypes.func,
  canIgnoreLowPowerStations: PropTypes.bool,
};

export default TabbedMaps;
