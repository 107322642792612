const fetchHomeChargers = async params => {
  // Remove null params
  Object.keys(params).forEach(key => params[key] == null && delete params[key]);

  let url = new URL(
    `${process.env.REACT_APP_EV_INFO_API_HOST}/chargers`
  );

  let searchParams = new URLSearchParams(params);

  url.search = searchParams;

  const response = await window.fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`
    }
  });

  const json = await response.json();
  const homeChargers = json.chargers ? json.chargers : json;

       const filteredChargersEnergy = homeChargers.filter(({ make, certifications }) => {
              const certificationsArray = certifications ? certifications.split(", ").map((cert) => cert.toLowerCase()) : [];
              return (certificationsArray.includes("ul") && certificationsArray.includes("energy star"));
       });

       const filteredChargersManufacturer = homeChargers.filter(({ make, certifications }) => {
              const isManufacturerCharger = make === 'Tesla' || make === 'Rivian' || make === "Ford" || make === "Chevrolet";
              return isManufacturerCharger;
       });

       const filteredChargers = [...filteredChargersEnergy, ...filteredChargersManufacturer];

       return filteredChargers;
};

export default fetchHomeChargers;