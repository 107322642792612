import typicalLoadProfile from "./typicalLoadProfile.json";

const loadProfiles = [
  {
    rateGroup: "TIERED",
    climateZone: "MODERATE",
    nemStatus: "NON-NEM",
    year: 2021,
    data: typicalLoadProfile,
  },
];

const getLoadProfileData = ({ rateGroup, climateZone, hasSolar = false }) => {
  return loadProfiles[0];
};

export default getLoadProfileData;
