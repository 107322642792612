import EN_CUSTOM from "../../client_customizations/context/translations/en_client_custom";

const EN = {
  electricVehicles: "Electric Vehicles",
  usedElectricVehicles: "Used Electric Vehicles",
  compareVehicles: "Compare New Vehicles",
  incentives: "Incentives",
  chargingStations: "Charging Stations",
  homeChargers: "Home Chargers",
  dealers: "Dealers",
  newVehicles: "New Vehicles",
  electricians: "Electricians",
  maps: "Maps",
  FAQ: "FAQ",
  events: "Events",
  pages: "Pages",
  home: "Home",
  contact: "Contact",
  preOwned: "USED VEHICLES",
  new: "NEW VEHICLES",
  pickTwo: "Pick at least two vehicles to begin the comparison.",

  electricity: "Electricity",
  gasoline: "Gasoline",
  total: "Total",
  description: "Description",
  detailedCalculations: "Details",
  charging: "Charging",
  emissions: "Emissions",
  source: "Source",
  vehicles: "Vehicles",
  make: "Make",
  model: "Model",
  all: "All",
  age: "Age",
  zipcode: "ZIP CODE",
  currency: "$",
  lbsperyear: "lbs/yr",
  kgperyear: "kg/yr",
  galperyear: "gal/yr",
  literperyear: "L/yr",
  "vehicle.gasoline.price": "Gasoline Price ($/gal)",
  "vehicle.gasoline.metricPrice": "Gasoline Price ($/L)",
  ft: "ft",
  m: "m",
  personalizeIncentives: "Personalize Incentives",

  "vehicle.miles": "miles",
  "vehicle.milesCaptital": "Miles",
  "vehicle.milesShort": "mi",
  "vehicle.trees": "trees",
  "vehicle.view": "View",
  "vehicle.more": "more",
  "vehicle.fewer": "fewer",
  "vehicle.greater": "greater",

  "vehicle.plugInHybrid": "Plug in Hybrid",
  "vehicle.allElectric": "All Electric",
  "vehicle.gas": "Gasoline",
  "vehicle.afterIncentives": "AFTER INCENTIVES",
  "vehicle.msrp": "MSRP",
  "vehicle.estimatedIncentives": "ESTIMATED INCENTIVES",
  "vehicle.type": "TYPE",
  "vehicle.fuelType": "FUEL TYPE",
  "vehicle.batterySize": "BATTERY SIZE",
  "vehicle.electricRange": "ELECTRIC RANGE",
  "vehicle.timeToChargeLvlTwo": "TIME TO CHARGE - LEVEL 2",
  "vehicle.milesPerThirtyFastCharge": "Miles per 30 Minutes of Fast Charging",
  "vehicle.coTwoEmissions": "CO2 EMISSIONS REDUCTION",
  "vehicle.phev": "Electricity and Gasoline",
  "vehicle.bev": "Electricity",
  "vehicle.age.one": "0-1 years old",
  "vehicle.age.two": "2-3 years old",
  "vehicle.age.four": "4+ years old",

  "vehicle.type.sedan": "Sedan",
  "vehicle.type.hatchback": "Hatchback",
  "vehicle.type.coupe": "Coupe",
  "vehicle.type.crossover": "Crossover",
  "vehicle.type.minivan": "Minivan",
  "vehicle.type.suv": "SUV",
  "vehicle.type.wagon": "Wagon",
  "vehicle.type.truck": "Truck",

  "header.newVehicles": "Discover New Vehicles",
  "header.usedVehicles": "Discover Used Vehicles",
  "header.electricVehicles": "Electric Vehicles",
  "header.compareVehicles": "Compare Vehicles",

  "header.incentives": "Incentives",
  "header.chargingStations": "Charging Stations",
  "header.homeChargers": "Home Chargers",

  "footer.electricVehicles": "{electricVehicles}",
  "footer.compareVehicles": "{compareVehicles}",
  "footer.incentives": "{incentives}",
  "footer.chargingStations": "{chargingStations}",
  "footer.homeChargers": "{homeChargers}",
  "footer.allRights": "All Rights Reserved.",
  "footer.poweredBy": "Powered by Zappyride",

  "homepage.welcome": "Colton Recharged",
  "homepage.welcomeSub":
    "Your electric car guide, brought to you by Colton Electric Utility. Estimate and compare costs, savings, EV Incentives, and more.",

  "homepage.linkCard.browseElectricVehicles":
    "BROWSE ELECTRIC {lineBreak} VEHICLES",
  "homepage.linkCard.discoverIncentives": "DISCOVER {lineBreak} INCENTIVES",
  "homepage.linkCard.locateChargingStations":
    "LOCATE CHARGING {lineBreak} STATIONS",
  "homepage.linkCard.homeChargers": "HOME {lineBreak} CHARGERS",
  "homepage.linkCard.findDealers": "Find {lineBreak} Dealers",

  "homepage.vehicleCarousel.title":
    "There are {vehicleCount} electric vehicles available. Discover yours.",
  "homepage.vehicleCarousel.subTitle":
    "Choose an EV and compare the cost to a similar gas vehicle. EVs can help you save money because {lineBreak} they're often cheaper to own and maintain than a 100% gasoline-powered competitor.",
  "homepage.vehicleCarousel.findEVButton": "See all vehicles",

  "graph.assumptions": "Assumptions",
  "graph.yourEV": "Your EV Selection",
  "graph.similarGasVehicle": "Similar Gas Vehicle",
  "graph.graphValues": "graph values",
  "graph.show": "Show ",
  "graph.hide": "Hide ",
  "graph.moreExpensive": "more expensive",
  "graph.cheaper": "cheaper",
  "graph.toOwnOver": "to own over ",
  "graph.years": " Years",
  "graph.year": " Year",

  "evs.graph.disclaimer":
    "Vehicles displayed may not reflect actual availability. {companyName} does not endorse or recommend any specific vehicle or car manufacturer.",
  "evs.graph.title": "Electric Vehicles",
  "evs.graph.compare":
    "Compare electric cars by EV range or price. Hover on the EV for more details.",
  "evs.graph.mobile":
    "To see the Range vs. Cost Graph, please view this page on a desktop.",
  "evs.graph.tiles": "Vehicle Tiles",
  "evs.graph.rangeVsCost": "Range vs. Cost",
  "evs.public.legend": "Public Stations",
  "evs.highPower.legend": "High Power Stations",
  "evs.other.legend": "Other Types of Stations",

  "graph.costOfOwnership.title": "Cost of Ownership",
  "graph.costOfOwnership.subTitle":
    "The {carName} is {style} {costDeltaText} {costDeltaTextEnding}",
  "graph.costOfOwnership.subTitleThreeCars":
    "The {carName} is the cheapest to own for {yearsOfOwnership} years",
  "graph.costOfOwnership.descriptionRow.vehicle": "Vehicle",
  "graph.costOfOwnership.descriptionRow.maintenance": "Maintenance",
  "graph.costOfOwnership.descriptionRow.insurance": "Insurance",
  "graph.costOfOwnership.descriptionRow.electricity": "Electricity",
  "graph.costOfOwnership.descriptionRow.gasoline": "Gasoline",
  "graph.costOfOwnership.totalRow": "Total",
  "graph.costOfOwnership.description": "Description",

  "graph.costOfOwnership.chart.vehicle": "Vehicle net Incentives, Resale",
  "graph.costOfOwnership.chart.electricity": "Electricity",
  "graph.costOfOwnership.chart.gasoline": "Gasoline",
  "graph.costOfOwnership.chart.maintenance": "Maintenance",
  "graph.costOfOwnership.chart.insurance": "Insurance",

  "graph.fuelRange.chart.description": "Description",
  "graph.fuelRange.chart.rangeOn": "Range on",
  "graph.fuelRange.chart.fuelBudget": "fuel budget",
  "graph.fuelRange.chart.network": "on Plug-In EVerywhere network",
  "graph.fuelRange.chart.unlimited": "Unlimited",
  "graph.fuelRange.chart.chargedHome": "charged at home",
  "graph.fuelRange.chart.similarGas": "a similar Gas Vehicle",
  "graph.fuelRange.chart.miles": "miles",
  "graph.fuelRange.chart.howFar":
    "How far can you drive the {car} on a {budget} fuel budget?",

  "graph.title.costAnalysis": "Cost Analysis",
  "graph.title.speedOfCharge": "Speed of Charge",
  "graph.title.electricRange": "Electric Range",
  "graph.title.miles": "Miles",
  "graph.title.totalRange": "Total Range",
  "graph.monthlyCostToFill.title": "Cost to Fill Up Monthly",

  "graph.costVsRange.afterIncentives": "From at {price} after incentives",
  "graph.costVsRange.electricRange": "Electric Range: {miles} miles",

  "graph.speedOfCharge.levelTwoChargingSpeed": "Level 2 Charger Speed",
  "graph.speedOfCharge.fastChargingSpeed": "Fast Charging Speed",
  "graph.speedOfCharge.mphLevelTwoCharger":
    "miles per hour with a level 2 charger",
  "graph.speedOfCharge.thirtyFastCharge":
    "miles per 30 minutes of fast charging",
  "graph.speedOfCharge.subTitle":
    "The {car} charges {number} {costDeltaText} {subTitleText}",
  "graph.speedOfCharge.mphLevelTwoChargerThreeCars":
    "charges the most miles per hour with a level 2 charger",
  "graph.speedOfCharge.thirtyFastChargeThreeCars":
    "charges the most miles per 30 minutes of fast charging",
  "graph.speedOfCharge.subTitleThreeCars": "The {car} {subTitleText}",
  "graph.speedOfCharge.mphLevelTwoChargerTitle":
    "Amount of Charge Per Hour of Level 2 Charging",
  "graph.speedOfCharge.fastChargerTitle":
    "Amount of Charge Per 30 Minutes of Fast Charging",
  "graph.electricRange.subTitleThreeCars":
    "The {car} has the longest Electric Range",
  "graph.electricRange.subTitle":
    "The {car}'s electric range is {costDeltaText} by {number} miles",
  "graph.electricRange.gasolineRange": "Gasoline Range",
  "graph.electricRange.assumption": "Range based on EPA Estimates",
  "graph.electricRange.assumptions": "Assumptions",
  "graph.electricRange.electricity": "Electricity",
  "graph.electricRange.gasoline": "Gasoline",
  "graph.electricRange.total": "Total",
  "graph.electricRange.description": "Description",
  "graph.monthlyCostToFill.subTitle":
    "The {carName} is {style} {costDeltaText} to fill up monthly",
  "graph.monthlyCostToFill.subTitleThreeCars":
    "The {carName} is cheapest to fill up monthly",

  "graph.showCostBreakdown": "Show Cost Breakdown",
  "graph.costDifferent": "Why is the cost different?",
  "graph.costToBreakdown": "Cost to Breakdown",
  "graph.costToBreakdown.subTitle":
    "Compare the cumulative lifetime cost of the {car1} to a {car2}",
  "graph.costToBreakdown.subTitleThreeCars":
    "Compare the cumulative lifetime cost of {car1}, {car2} and {car3}",
  "graph.costToBreakdown.note":
    "Note: Breakeven chart assumes the vehicles are purchased in cash.",

  "homepage.incentives.title":
    "Explore potential EV incentives and tax credits",
  "homepage.incentives.subTitle":
    "See how much you could save getting behind the wheel of an EV, whether you are buying or leasing. {lineBreak} Incentives are personalized for where you live.",
  "homepage.incentives.exporeIncentives": "EXPLORE INCENTIVES",

  "compareVehicles.subTitle": "See All Vehicles >",
  "compareVehicles.selectFuelTitle": "FUEL TYPE",
  "compareVehicles.selectMakeTitle": "CAR MAKE",
  "compareVehicles.selectManufacturer": "MANUFACTURER",
  "compareVehicles.selectModelTitle": "CAR MODEL",
  "compareVehicles.selectYearTitle": "YEAR",
  "compareVehicles.selectFuel": "Select Fuel Type",
  "compareVehicles.selectMake": "Select Car Make",
  "compareVehicles.selectModel": "Select Car Model",
  "compareVehicles.selectYear": "Select Year",
  "compareVehicles.pickTwo": "Pick at least 2 vehicles to start the comparison",
  "compareVehicles.disclaimer": "Not all make / models are available.",

  "ev.used.yearRange": "Year Range",
  "ev.used.electricRange": "Electric Range Average",

  "ev.matchScore.title": "Match Score",
  "ev.matchScore.totalScore": "Total Score",
  "ev.matchScore.seats": "Seats",
  "ev.matchScore.range": "Range",
  "ev.matchScore.charging": "Charging",
  "ev.matchScore.budget": "Budget",
  "ev.matchScore.adjustment": "Adjustment",
  "ev.matchScore.maxScore": "Max Score",
  "ev.matchScore.yourScore": "Your Score",
  "ev.matchScore.popover":
    "Your Match Score is based on your selected preferences.",

  "evs.welcomeSub":
    "Tell us a little bit about your driving style and preferences so we {lineBreak} can show you the EVs that might best fit your lifestyle!",
  "evs.welcomeSubMobile":
    "Tell us a little bit about your driving style and preferences so we can show you the EVs that might best fit your lifestyle!",
  "evs.matchScoreAndFilters": "Refine Match Score and Filters",
  "evs.matchScoreOptions": "Refine Match Score",
  "evs.roundTripCommute": "Average daily miles",
  "evs.budgetAfterIncentives": "Budget after Incentives",
  "evs.minSeats": "Minimum Seats",
  "evs.seats": "seats",
  "evs.homeChargingAvailability": "Home Charging Availability",
  "evs.homeChargingAvailabilityTooltip":
    "More electric vehicles will be convenient for you if you can charge quickly at home.",
  "evs.noCharging": "No Charging",
  "evs.levelOne": "Level 1",
  "evs.levelTwo": "Level 2",
  "evs.helpMeChoose": "Help me choose",
  "evs.chargingAvailability": "Charging Availability",
  "evs.chargerCatalog.noChargers": "No Chargers found",
  "evs.chargercard.chargingtime.na": "NA",
  "evs.disclaimer":
    "Vehicles displayed may not reflect actual availability. We do not endorse or recommend any specific vehicle or car manufacturer.",

  "chargingWizard.carOvernight": "Where will you park your car overnight?",
  "chargingWizard.parkingGarage": "Private garage or parking spot",
  "chargingWizard.onTheStreet": "On the street",
  "chargingWizard.publicParking": "Public parking lot",
  "chargingWizard.electricianInstall":
    "Would an electrician be allowed to install a charging station?",
  "chargingWizard.overnightParking":
    "Could you park the car overnight in a spot equipped with a charging station?",
  "chargingWizard.powerOutlet":
    "Is there a regular power outlet close to where you park your car?",
  "chargingWizard.chargingAvailability": " charging availability",
  "chargingWizard.noChargingAvailability": "no charging availability",
  "chargingWizard.youWouldHave": "you would have {resultText} ",
  "chargingWizard.useThisValue": "Use this value",

  evfilter: "Filter",
  "evfilter.fuel": "Fuel",
  "evfilter.fuelTooltip":
    "All-electric vehicles use electricity only. Plug-in hybrids can use electricity and gasoline.",
  "evfilter.type": "Type",

  yes: "Yes",
  no: "No",

  "evCard.electricRange": "Electric Range",
  "evCard.totalRange": "Total Range",
  "evCard.matchScore": "MATCH SCORE",
  "evCard.seeDetails": "See Details",
  "evCard.seeElectricVehicle": "SEE ELECTRIC VEHICLE",

  "evSort.title": "SORT BY",
  "evSort.matchScore": "Match score",
  "evSort.electricRange": "Electric Range",
  "evSort.priceLtH": "Price: Low to High",
  "evSort.priceHtL": "Price: High to Low",
  "evSort.alphabetical": "Alphabetical",
  "evSort.chargeSpeed": "Charge speed: Fastest first",

  "pricePanels.cash": "Cash",
  "pricePanels.loan": "Loan",
  "pricePanels.lease": "Lease",
  "pricePanels.loanPayment": "Loan Payment",
  "pricePanels.downPayment": "Down Payment",
  "pricePanels.leasePayment": "Lease Payment",
  "pricePanels.perMonth": " / month",
  "pricePanels.downPaymentDescription": "10% of MSRP plus tax",
  "pricePanels.firstLeasePayment": "FIRST LEASE PAYMENT",
  "pricePanels.incentivesForLease": "Incentives For Lease",
  "pricePanels.seeAllIncentives": "See All Incentives",
  "pricePanels.seeAllCosts": "See All Costs",

  "ev.jumbotron.viewAllCars": "View all cars",
  "ev.jumbotron.findADealer": "Find a Dealer",
  "ev.carDetails.view": "View vehicle details",
  "ev.carDetails.calc.charging": "Charging",
  "ev.carDetails.calc.fuel": "Fuel",
  "ev.carDetails.calc.emissions": "Emissions",
  "ev.carDetails.calc.description": "Description",
  "ev.carDetails.calc.source": "Source",
  "ev.carDetails.calc.DCFC": "Maximum DC Fast Charging Rate (DCFC)",
  "ev.carDetails.calc.kwPerMile": "kWh per 100 miles",
  "ev.carDetails.calc.milesDriven": "Miles Driven Per Year",
  "ev.carDetails.calc.milesPerGallonBasic": "Miles Per Gallon",
  "ev.carDetails.mobile":
    "For more details including charts {amp} calculations, please view this page on a desktop.",
  "ev.carDetails.fastChargingTooltip": "DC Fast Charger Speed:",
  "ev.carDetails.levelTwoChargerSpeedTooltip":
    "When charged with a public or private Level 2 charger capable of supporting this vehicle's maximum on-board charge rate.  Note that The Consumers Energy residential rebate program has a best practice power rating not exceeding 240V/40A (9.6 KW) circuits for residential charging installations.",
  "ev.carDetails.batterySizeTooltip":
    "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. A typical laptop battery can hold less than 0.1 kWh.",
  "ev.carDetails.treesPlanted": "Trees Planted",
  "ev.carDetails.gasolineSaved": "Gasoline Saved",
  "ev.carDetails.compareText":
    "We selected the {gasVehicle} based on its proximity to the {vehicle} based on brand, size, and price.",
  "ev.cardetails.calc.dcRate": "DC Fast Charging (DCFC) Rate",
  "ev.cardetails.calc.EPA": "EPA Efficiency Rating",
  "ev.cardetails.calc.chargingRate": "Charging Rate",
  "ev.cardetails.calc.vehicleBatteryCapacity": "Vehicle Battery Capacity",
  "ev.cardetails.calc.emissionsReductions": "Emissions Reductions",
  "ev.cardetails.calc.maxACIntake": "Maximum AC Charge Rate (Level 2)",
  "ev.cardetails.calc.lvlTwoChargingRate": "Level 2 Charging Rate",
  "ev.cardetails.calc.minOflvl2AC":
    "Minimum of Level 2 Charging Rate and Vehicle Max AC Intake",
  "ev.cardetails.calc.lvl2FullCharge": "Minimum Level 2 Charge Time",
  "ev.cardetails.calc.milesDrivenPerYr": "Miles driven per year",
  "ev.cardetails.calc.userInput": "User Input",
  "ev.cardetails.calc.milesPerGallon": "Miles Per Gallon (MPG)",
  "ev.cardetails.calc.mpg": " miles/gal",
  "ev.cardetails.calc.portionElectric": "Portion of Electric Driving",
  "ev.cardetails.calc.portionElectricSource":
    "User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline.",
  "ev.cardetails.calc.gallonsUsed": "Gallons Used",
  "ev.cardetails.calc.gallonsUsedFormula":
    "Miles driven * (1 - Portion of Electric Driving) / MPG",
  "ev.cardetails.calc.gallonsSaved": "Gallons Saved",
  "ev.cardetails.calc.gallonsSavedFormula":
    "Gallons used by Equivalent Gas Vehicle - Gallons used by EV",
  "ev.cardetails.calc.electricEmissions": "Electric Emissions in Lbs",
  "ev.cardetails.calc.electricEmissionsLbsMwh": "Electric Emissions in Lbs/MWh",
  "ev.cardetails.calc.EIA": "U.S. Energy Information Administration",
  "ev.cardetails.calc.gasolineEmissionsYr":
    "Gasoline Emissions in lbs of CO2/yr",
  "ev.cardetails.calc.gasolineEmissions":
    "Gasoline Emissions in Lbs per Gallon",
  "ev.cardetails.calc.emissionsReudctions": "Emissions Reductions",
  "ev.cardetails.calc.lbsCo2PerYr": " lbs of CO2 per year",
  "ev.cardetails.calc.gasolineEmissionsLbs": "Gasoline Emissions in Lbs",
  "ev.cardetails.calc.arborDayFoundation": "Arbor Day Founadtion",
  "ev.cardetails.calc.emissionsPerTree": "Emissions Saved per Tree",
  "ev.cardetails.calc.electricityEmissions":
    "Electricity Emissions in CO2 lbs/MWh",
  "ev.usedCarTitle": "Used {car}",
  "ev.cardetails.electricRange":
    "To account for battery degradation, the range quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",
  "ev.carDetails.usedBatterySizeTooltip":
    "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. To account for battery degradation for a used EV, the battery size quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",

  "ev.purchaseMethod": "Purchase Method",
  "ev.purchaseMethodToolTip":
    "In a lease, you have to return the car after the lease period. In the case of loan financing, you keep the car after the loan is paid off.",
  "ev.cash": "Cash",
  "ev.loan": "Loan",
  "ev.lease": "Lease",
  "ev.milesDrivenAnnually": "Miles Driven Per Year",
  "ev.electricPortion": "Portion of Electric Miles",
  "ev.electricPortionTooltip":
    "Estimate what percentage of your driving miles will be completely electric. If you recharge daily and drive less per day than the all-electric range, your percentage will be near 100%. For most typical driving beyond all-electric range, 50% is a safe assumption.",
  "ev.yearsOwnership": "Years of Ownership/Lease",

  "ev.interestRate": "Interest Rate",
  "ev.electricityRateCalc":
    "Electricity rate is calculated as a blended rate based off an average household consumption of 700 kWh. To learn more, please click {website}",
  "ev.costOfPublicCharing": "Est. cost of public charging",
  "ev.publicPrivateCharging": "Mix public/private charging",
  "ev.public": "Public",

  "assumption.salesTaxPercent": "Sales Tax %",
  "assumption.salesTax": "Sales Tax",
  "assumption.gasolinePrice": "Price of Gasoline",
  "assumption.downPayment": "Down Payment",
  "assumption.electricityRate": "Electricity Rate",
  "assumption.downPaymentValue": "10% of MSRP + Tax",

  "ev.compareGasVehicle":
    "Compare the {car} to a similar gas vehicle, the {gasCar}",
  "ev.compareOtherVehicles": "COMPARE OTHER VEHICLES",
  "ev.incentives.title":
    "Up to {savings} in tax credits and rebates are potentially available {lineBreak} for the {car}",
  "ev.incentives.title.simple": "Available Incentives and Rebates",
  "ev.reviews": "Reviews from around the web",

  "chargingMap.updateZipcode": "Update zip code",
  "chargingMap.errorZipcode": "Error: {workingZipcode} is not a valid zipcode",
  "chargingMap.showFullListOfStations": "FULL LIST OF STATIONS",
  "chargingMap.public": "Public stations",
  "chargingMap.businessGovernment": "Installed by business or government",
  "chargingMap.highPowerStations": "High Power Stations",
  "chargingMap.DCChargers": "DC fast charge or superchargers",
  "chargingMap.otherSttations": "Other Types of Stations",
  "chargingMap.privateStations": "Private stations",
  "chargingMap.searchDealers": "Search Qualified Dealers",
  "chargingMap.disclaimer":
    "Data courtesy of the Alternative Fuel Data Center. Map may not reflect latest availability of charging stations.",

  "dealers.brands": "Brands",
  "dealers.allBrands": "All Brands",
  "dealers.allDealers": "All Dealers",
  "dealers.contact": "CONTACT",
  "dealers.website": "WEBSITE",
  "dealers.findDealers": "Find Dealers",

  "incentives.title": "Electric Vehicle and Charger Incentives",
  "incentives.subTitle":
    "You may be eligible for a range of incentives, including rebates, tax credits, and various other benefits. Incentives are personalized for where you live.",
  "incentives.single": "Single",
  "incentives.married": "Married",
  "incentives.headOfHousehold": "Head of Household",
  "incentives.vehicleType": "Vehicle Type",
  "incentives.vehicleTypeTooltip":
    "Certain incentives depend on which car you purchase.",
  "incentives.tradeIn": "Retiring a Vehicle?",
  "incentives.income": "Income and tax status",
  "incentives.incomeTooltip":
    "Incentive eligibility and the amount you can receive often depends on your income. Lower  income customers are typically eligible for higher rebates.",
  "incentives.clunkerNone":
    "Certain incentives compensate you for retiring an existing vehicle, also known as a clunker.",
  "incentives.clunkertoolTip":
    "Certain incentives compensate you for retiring an existing vehicle, also known as a clunker. Specifically, the ",
  "incentives.clunkertoolTipContd":
    " are available in your region. Eligibility requirements vary by incentive; please check program details.",
  "incentives.retiringVehicleTooltip":
    "Retiring an old gas vehicle may be required for certain incentives.",
  "incentives.householdIncomeTooltip":
    "Combine income from all household members. Used to determine incentive eligibility.",
  "incentives.householdSizeTooltip":
    "Include all members of your household (spouse, deoendants, etc). Used to determine incentived eligibility.",
  "incentives.taxFilingStatusTooltip":
    "Used to determine incentive eligibility.",
  "incentives.householdSize": "Household Size",
  "incentives.electricityProvider": "Electricity Provider",
  "incentives.householdIncome": "Household Income",
  "incentives.planClunker": "Are you planning to turn in a clunker?",
  "incentives.location": "Location",
  "incentives.locationTooltip":
    "Many incentives depend on where you live. Enter your zipcode to help determine what incentives are available where you live.",
  "incentives.taxFilingStatus": "Tax Filing Status",
  "incentives.whichVehicle": "Which vehicle do you plan to purchase?",
  "incentives.incentiveEligibility": "Update Incentive Eligibility",

  "homeChargers.title": "Home Chargers",
  "homeChargers.selectMake": "Select a Make",
  "homeChargers.selectModel": "Select a Model",
  "homeChargers.subText":
    "Select the vehicle you are interested in to see the specific charging time to a full battery.",
  "homeChargers.wallMounted": "Mounted",
  "homeChargers.portable": "Portable",
  "homeChargers.price": "Price",
  "homeChargers.cordLength": "Cord Length",
  "homeChargers.wifi": "WiFi Connectivity",
  "homeChargers.socket": "Socket",
  "homeChargers.socketTooltip":
    "Different model chargers have different plug patterns. These plug patterns correspond to the way prongs fit into your wall outlet. If you're note sure which is best for your home/garage, check with a qualified electrician.",
  "homeChargers.cordLengthLtH": "Cord Length: Low to High",
  "homeChargers.cordLengthHtL": "Cord Length: High to Low",
  "homeChargersCard.buy": "Details & Buy",
  "homeChargersCard.beforeIncentives": "Before Incentives",
  "homeChargersCard.socket": "{socket} Socket",
  "homeChargersCard.teslaWarning": "This charger only works with Teslas",

  "homeChargers.cable": "{length} ft. Cable",
  "homeChargers.hardwired": "Hardwired",
  "homeChargers.toFullCharge": "to full charge",

  "electricians.residential": "Residential",
  "electricians.commercial": "Commercial",
  "electricians.industrial": "Industrial",
  "electricians.type": "Electrician Type",
  "electricians.searchElectricians": "Search Qualified Electricians",

  "electricians.map.header": "Benefits of our Preferred Partners",
  "electricians.map.value": "{title} We offer competitive installation prices.",
  "electricians.map.personalizedExperience":
    "{title} Our EV Specialists can help guide you through the entire process.",
  "electricians.worldClass":
    "{title} We partner with experienced electricians who are vetted to ensure a safe and professional installation",
  "electricians.fastEfficient":
    "{title} Our install timelines are secured and expedited to help get you up and running sooner.",

  "tabbed-maps-dealer-catalog-tab": "Dealers",
  "tabbed-maps-all-stations-tab": "Charging Stations",
  "tabbed-maps-electricians-tab": "Electricians",
  "tabbed-maps-route-tab": "Map a Route",

  "ev.chargingStations.body":
    " It's easier than ever to charge on the go. Enter your location to {lineBreak} see all charging stations near you.",
  "ev.planningRoute.body":
    "It’s easier than ever to charge on the go. Enter your destination to see all of the charging stations along the way.",
  "ev.dealers.body":
    "Discover electric vehicle dealers in your area, and click on each one to see location and contact information.",
  "ev.showSources.electricityRate":
    "Electricity rate is based off of {website}",
  "ev.showSources.portionElectricSource":
    "User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline.",
  "ev.showSources.resaleValue": "Resale Value",
  "ev.showSources.resaleValueSource":
    "{carName}: e^(ln({resaleFactor})/36000*{lifetimeMiles})*MSRP",
  "ev.showSources.totalVehicleCost": "Total Vehicle Cost",
  "ev.showSources.cashFormula":
    "Vehicle MSRP * (1 + Sales Tax) - Incentives - Resale Value",
  "ev.showSources.assumption": "Assumption:",
  "ev.showSources.loanAmount": "Loan Amount",
  "ev.showSources.loanFormula": "MSRP * (1 + Sales Tax) - Down Payment",
  "ev.showSources.monthlyPayments": "Monthly Payments",
  "ev.showSources.monthlyPaymentsSource":
    "Financial function of Loan Amount, Interest Rate, and Months of Ownership",
  "ev.showSources.monthsOfOwnership": "Months of Ownership",
  "ev.showSources.totalMonthlyPayments": "Total Monthly Payments",
  "ev.showSources.totalMonthlyPaymentsSource":
    "Monthly Payment * Months of Ownership",
  "ev.showSources.totalLoanFormula":
    "Down Payment + Total Monthly Payments - Incentives - Resale Value",
  "ev.showSources.capitalizedIncentives": "Capitalized Incentives",
  "ev.showSources.capitalizedIncentivesSource":
    "Incentives capitalized within the lease",
  "ev.showSources.capitalizedCost": "Capitalized Cost",
  "ev.showSources.capitalizedCostSource":
    "MSRP - Down Payment - Capitalized Incentives",
  "ev.showSources.monthlyDepreciationCost": "Monthly Depreciation Cost",
  "ev.showSources.monthlyDepreciationCostSource":
    "(Capitalized Cost - Resalve Value) / Months of Ownership",
  "ev.showSources.monthlyFinancingCost": "Monthly Financing Cost",
  "ev.showSources.monthlyFinancingCostSource":
    "(Capitalized Cost + Resale Value) * Money Factor",
  "ev.showSources.moneyFactor": "Money Factor",
  "ev.showSources.moneyFactorSource":
    "Equivalent to user input of {interestRateAsBasisPoints}",
  "ev.showSources.monthlyLeasePayment": "Monthly Lease Payment",
  "ev.showSources.monthlyLeasePaymentSource":
    "(Monthly Depreciation Cost + Monthly Financing Cost) * (1 + Sales Tax)",
  "ev.showSources.firstLeasePayment": "First Lease Payment",
  "ev.showSources.firstLeasePaymentSource":
    "Down Payment + Monthly Lease Payment",
  "ev.showSources.nonCapLeaseIncentives": "Non-capitalized Lease Incentives",
  "ev.showSources.nonCapLeaseIncentivesSource":
    "Incentives not captured in capitalized lease cost",
  "ev.showSources.leaseForumla":
    "First Lease Payment + (Months Of Ownership - 1) * (Monthly Lease Payment) - Non-capitalized Lease Incentives",
  "ev.showSources.vehicleKWh": "Vehicle's kWh per 100 mi",
  "ev.showSources.EPAEfficiency": "EPA Efficiency Rating",
  "ev.showSources.dollarPerMileDriven": "$/100 mi driven",
  "ev.showSources.dollarPerMileDrivenSource":
    "Electricity Cost * Vehicle's kWh per 100 mi",
  "ev.showSources.dollarPerMileDrivenPublicCharging":
    "(Estimated Cost of Public Charging * Mix Public/Private Charging + Electricity Rate * (1 - Mix Public/Private Charging)) * Vehicle kWh per 100 mi",
  "ev.showSources.lifetimeMiles": "Lifetime Miles",
  "ev.showSources.totalElectricityCost": "Total Electricity Cost",
  "ev.showSources.totalElectricityCostSource":
    "$/100 mi driven * Lifetime Miles * {portionEVDriving}/ 100",
  "ev.showSources.gasolineCost": "Gasoline Cost",
  "ev.showSources.vehicleMPG": "Vehicle's MPG (miles per gallon)",
  "ev.showSources.totalGasolineCost": "Total Gasoline Cost",
  "ev.showSources.totalGasolineCostSource":
    "Gasoline Cost * Lifetime Miles *  {portionEVDriving}/ MPG",
  "ev.showSources.maintenancePerMile": "Maintenance per mile for gasoline",
  "ev.showSources.maintenancePerMileSource":
    "Extrapolation of average maintenance cost of {avgMaintenanceCost}, driven by vehicle MSRP in relation with average MSRP of {averageMSRP}",
  "ev.showSources.maintenanceCostReduction": "EV Maintenance Cost Reduction",
  "ev.showSources.maintenanceCostReductionSource": "{website}",
  "ev.showSources.maintenancePerMileElectricity":
    "Maintenance per mile for electricity",
  "ev.showSources.maintenancePerMileElectricitySource":
    "Maintenance per mile for gasoline * EV Maintenance Reduction Factor",
  "ev.showSources.blendedMaintenanceCostPerMile":
    "Blended maintenance cost per mile",
  "ev.showSources.blendedMaintenanceCostPerMileSource":
    "Portion of Electric Driving applied to Maintenance per mile for gasoline and for electricity",
  "ev.showSources.totalMaintenanceCost": "Total Maintenance Cost",
  "ev.showSources.totalMaintenanceCostSource":
    "Maintenance cost per mile * Lifetime Miles",
  "ev.showSources.averageInsurancePerYear": "Average Insurance per Year",
  "ev.showSources.insurancePerYear": "Insurance per Year",
  "ev.showSources.insurancePerYearSource":
    "Extrapolation of average insurance cost in {state}, driven by vehicle MSRP in relation with average MSRP of {msrp}",
  "ev.showSources.yearsOfOwnership": "Years of Ownership",
  "ev.showSources.totalInsuranceCost": "Total Insurance Cost",
  "ev.showSources.totalInsuranceCostSource":
    "Insurance cost per year * Years of Ownership",
  "ev.showSources.estimatedCostOfPublicCharging":
    "Estimated Cost of Public Charging",
  "ev.showSources.mixPublicPrivateCharging": "Mix Public/Private Charging",
  "ev.showSources.insuranceSource": "Average Insurance Cost In ",
  here: "here",
  sources: "sources",

  "events.subHeader":
    "See, touch and feel an electric vehicle for yourself at an upcoming event.",
  eventsFilters: "Filters",
  eventsDateRange: "Date Range",
  "events.nationWide": "Nationwide",
  "events.allLocations": "All Locations",
  "events.inPerson": "In Person",
  "events.virtual": "Virtual",
  "events.rideAndDrive": "Ride and Drive",
  "events.autoshow": "Autoshow",
  "events.educationEvent": "Education Event",
  "events.training": "Training",
  "events.webinar": "Webinar",
  "events.conference": "Conference",
  "events.distance": "Distance",
  "events.inPersonOrVirtual": "In Person or Virtual",
  "eventCard.inPerson": "In Person Event",
  "eventCard.virtualEvent": "Virtual Event",
  "eventCard.inPersonAndVirtual": "In Person + Virtual Event",
  "eventCard.learnMore": "Learn More",
  "eventCard.hostedBy": "Hosted by",
  "eventSort.dateLtH": "Date (Soonest first)",

  "chargingStations.mapLegend.public": "Public Stations",
  "chargingStations.mapLegend.publicSubtext":
    "Installed by business or government",
  "chargingStations.mapLegend.high": "High Power Stations",
  "chargingStations.mapLegend.highSubtext": "DC fast charge or superchargers",
  "chargingStations.mapLegend.other": "Other Types of Stations",
  "chargingStations.mapLegend.private": "Private Stations",

  "eventCatalog.noMatch": "We didn't find any matches.",
  "eventCatalog.noEvents":
    "No events matching your current search criteria can be found. Try changing your search criteria",

  "results.updated": "Results have been updated as of ",
  "vehicles.none": "No vehicles found",
  loading: "Loading",
  linkUnavailable: "Link to Network Unavailable",

  //Metric System Translations

  "vehicle.kilometers": "kilometers",
  "vehicle.kilometersCapital": "Kilometers",
  "vehicle.kilometersShort": "km",
  "ev.cardetails.calc.lpkm": " liters/kilometer",
  "vehicle.kilometersPerThirtyFastCharge":
    "Kilometers per 30 min of fast charging",
  "ev.kilometersDrivenAnnually": "Kilometers Driven Per Year",
  "ev.showSources.portionElectricSourceKilometer":
    "User input. For a hybrid vehicle, estimates the portion of kilometers driven using the electric battery instead of gasoline.",
  "ev.showSources.lifetimeKilometers": "Lifetime Kilometers",
  "ev.showSources.kilometerVehicleKWh": "Vehicle's kWh per 100 km",
  "ev.showSources.dollarPerKilometerDriven": "$/100 km driven",
  "ev.showSources.dollarPerKilometerDrivenPublicCharging":
    "(Estimated Cost of Public Charging * Mix Public/Private Charging + Electricity Rate * (1 - Mix Public/Private Charging)) * Vehicle kWh per 100 km",
  "ev.showSources.dollarPerKilometerDrivenSource":
    "Electricity Cost * Vehicle's kWh per 100 km",
  "ev.showSources.totalKilometerElectricityCostSource":
    "$/100 km driven * Lifetime Kilometers * {portionEVDriving}/ 100",
  "ev.showSources.vehiclelPerKm": "Vehicle's LPerKm (liters per kilometer)",
  "ev.showSources.totalKilometerGasolineCostSource":
    "Gasoline Cost * Lifetime Kilometers *  {portionEVDriving}/ lPerKm",
  "ev.showSources.maintenancePerKilometer":
    "Maintenance per kilometer for gasoline",
  "ev.showSources.maintenancePerKilometerElectricity":
    "Maintenance per kilometer for electricity",
  "ev.showSources.maintenancePerKilometerElectricitySource":
    "Maintenance per kilometer for gasoline * EV Maintenance Reduction Factor",
  "ev.showSources.blendedMaintenanceCostPerKilometer":
    "Blended maintenance cost per kilometer",
  "ev.showSources.blendedMaintenanceCostPerKilometerSource":
    "Portion of Electric Driving applied to Maintenance per kilometer for gasoline and for electricity",
  "ev.showSources.totalKilometerMaintenanceCostSource":
    "Maintenance cost per kilometer * Lifetime Kilometers",
  "graph.speedOfCharge.kilometersThirtyFastChargeThreeCars":
    "charges the most kilometers per 30 minutes of fast charging",
  "graph.costVsRange.electricKilometerRange":
    "Electric Range: {kilometers} kilometers",
  "graph.speedOfCharge.kilometersLevelTwoChargerThreeCars":
    "charges the most kilometers per hour with a level 2 charger",
  "graph.speedOfCharge.kmLevelTwoCharger":
    "kilometers per hour with a level 2 charger",
  "graph.speedOfCharge.kmThirtyFastCharge":
    "kilometers per 30 minutes of fast charging",
  "graph.fuelRange.chart.kilometers": "kilometers",
  "homeChargers.meterCable": "{length} m Cable",
  "ev.carDetails.kilometerBatterySizeTooltip":
    "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. A typical laptop battery can hold less than 0.1 kWh.",
  "ev.carDetails.kilometerUsedBatterySizeTooltip":
    "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 kilometers, depending on the vehicle. To account for battery degradation for a used EV, the battery size quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",
  "ev.carDetails.calc.kwPerKm": "kWh per 100 km",
  "ev.cardetails.calc.EPAMetric": "NRC Efficiency Rating",
  "ev.cardetails.calc.kmDrivenPerYr": "Kilometers Driven Per Year",
  "ev.cardetails.calc.lPerKm": "Liters Per 100 Kilometers",
  "ev.cardetails.calc.lPer100Km": "L/100 km",
  "ev.cardetails.calc.litersUsed": "Liters Used",
  "ev.cardetails.calc.litersUsedFormula":
    "Kilometers driven * (1 - Portion of Electric Driving) * L/100 km",
  "ev.cardetails.calc.litersSaved": "Liters Saved",
  "ev.cardetails.calc.litersSavedFormula":
    "Liters used by Equivalent Gas Vehicle - Liters used by EV",
  "ev.cardetails.calc.electricityEmissionsMetric":
    "Electricity Emissions in CO2 kg/MWh",
  "ev.cardetails.calc.FuelConsumptionCa": "Canada Fuel Consumption Guide",
  "ev.cardetails.calc.nationalAverageElectricityEmissions":
    "National Average Electricity Emissions",
  "ev.cardetails.calc.electricEmissionsMetric": "Electric Emissions in kg",
  "ev.cardetails.calc.electricEmissionsLbsMwhMetric":
    "Electric Emissions in Kg/MWh",
  "ev.cardetails.calc.gasolineEmissionsYrMetric":
    "Gasoline Emissions in kg of CO2/yr",
  "ev.cardetails.calc.gasolineEmissionsMetric":
    "Gasoline Emissions in Kg per Liter",
  "ev.cardetails.calc.kgCo2PerYr": " kgs of CO2 per year",
  "ev.cardetails.calc.gasolineEmissionskg": "Gasoline Emissions in Kg",
};

export default { ...EN, ...EN_CUSTOM };