import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import logo from "../../../assets/images/logo.svg";
import "./Header.scss";
import { FormattedMessage } from "react-intl";

const Header = ({ page }) => {
  const [collapse, setCollapse] = useState(false);
  const isHomepage =
    window.location.pathname === "/" || window.location.pathname === null;

  const vehiclesActiveTabs = [
    "vehicles",
    "compare",
    "compare used",
    "tabbed-maps-dealer-catalog-tab",
  ];

  return (
    <div className="Header">
      <div className="container" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div style={{ paddingTop: "0.75rem", paddingBottom: "0.75rem" }}>
            <a
              href="https://consumersenergy.com"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-brand"
            >
              <img src={logo} alt="{process.env.REACT_APP_FULL_COMPANY_NAME}" />
            </a>
          </div>

          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="ml-auto" navbar>
              {process.env.REACT_APP_PAGES_HOME_ENABLED ? (
                <NavItem>
                  <a
                    href="https://www.consumersenergy.com/residential/programs-and-services/electric-vehicles"
                    className={isHomepage ? "active" : ""}
                  >
                    <span>EV Home</span>
                  </a>
                </NavItem>
              ) : null}

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  caret
                  className={vehiclesActiveTabs.includes(page) ? "active" : ""}
                >
                  <span>Vehicles</span>
                </DropdownToggle>

                <DropdownMenu right>
                  <DropdownItem
                    key={`nav-item-electricEV`}
                    title={page === "vehicles" ? "Active Page" : null}
                    tag={NavLink}
                    to={"/vehicles"}
                    role="menuitem"
                  >
                    Find Electric Vehicles
                  </DropdownItem>

                  <DropdownItem
                    key={`nav-item-compareEV`}
                    title={page === "compare" || page === "compare used" ? "Active Page" : null}
                    tag={NavLink}
                    to={"/compare-vehicles"}
                    role="menuitem"
                  >
                    Compare Vehicles
                  </DropdownItem>
                  <DropdownItem
                    key={`nav-item-dealersEV`}
                    title={page === "dealers" ? "Active Page" : null}
                    tag={NavLink}
                    to={"/dealers"}
                    role="menuitem"
                  >
                    Dealer Locator
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <NavLink
                  title={page === "incentives" ? "Active Page" : null}
                  to="/incentives"
                >
                  <span>
                    <FormattedMessage
                      id="incentives"
                      defaultMessage="Incentives"
                      description="Incentives Header"
                    />
                  </span>
                </NavLink>
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  caret
                  className={page === "map" ? "active" : ""}
                >
                  <span>Charging</span>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    key="nav-item-tabbed-maps-all-stations-tab"
                    title={page === "map" ? "Active Page" : null}
                    tag={NavLink}
                    to="/charging-stations"
                    role="menuitem"
                  >
                    Charger Map
                  </DropdownItem>
                  <DropdownItem
                    key="nav-item-tabbed-maps-route-tab"
                    title={page === "map" ? "Active Page" : null}
                    tag={NavLink}
                    to="/map-a-route"
                    role="menuitem"
                  >
                    Plan Your Route
                  </DropdownItem>
                  <DropdownItem
                    key={`nav-item-home-chargers`}
                    title={page === "home-chargers" ? "Active Page" : null}
                    to="/home-chargers"
                    tag={NavLink}
                    role="menuitem"
                  >
                    Shop Chargers
                  </DropdownItem>
                  <DropdownItem
                    key="nav-item-tabbed-maps-electricians-tab"
                    title={page === "map" ? "Active Page" : null}
                    to="/electricians"
                    tag={NavLink}
                    role="menuitem"
                  >
                    Electrician Finder
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <NavLink
                    title={page === "rates" ? "Active Page" : null}
                    to="/rates"
                  >
                    <span>Rates</span>
                  </NavLink>
              </NavItem>
              <NavItem>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.consumersenergy.com/residential/programs-and-services/electric-vehicles/contact-ev-specialist"
                >
                  <span>EV Specialist</span>
                </a>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
