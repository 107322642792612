import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const HomepageBigPromise = ({ homePageBannerImage }) => {
  return (
    <section className="p-0" style={{ backgroundColor: 'black' }}>
      <div className="big-promise" style={{ position: "relative" }}>
        <div className="gradient-top-left" />
        <div
          style={{
            backgroundImage: "url(" + homePageBannerImage + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
            backgroundSize: "cover",
            padding: 0,
            textAlign: "center",
            height: "475px",
            boxSizing: "border-box",
          }}
        />
        <div className="container big-promise-text">
          <div>
            <h2>Your guide to EVs</h2>
            <p>Estimate and compare costs, savings, incentives & more.</p>
            <Link to="/vehicles">
              <button>Find your EV</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string,
};
