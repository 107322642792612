import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormatAsDollars } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";
import {useIntl} from 'react-intl';

const descriptionFn = val => {
  return `< ${FormatAsDollars(val)}`;
};

const SlideHomeChargerPrice = ({
  id = "charger-price-range",
  description = descriptionFn,
  ...rest
}) => {
  const intl = useIntl()
  const label = intl.formatMessage({ id: "homeChargers.price", defaultMessage: "Price"})
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Range
      id={id}
      value={userPrefs.get("chargerBudget")}
      label={label}
      rangeMin={200}
      rangeMax={5000}
      rangeStep={100}
      description={description}
      ariaControls="home-chargers-catalog"
      handler={e => userPrefs.set({ chargerBudget: e.target.value })}
      {...rest}
    />
  );
};

export default SlideHomeChargerPrice;

SlideHomeChargerPrice.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
