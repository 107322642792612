import React, { useContext, useState, useEffect } from "react";
import { FormatCarModelAndTrim } from "../../../../utils/Helpers/Format";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";
import Select from "../../../../components/shared/InputElements/Select";
import getEquivalent from "../../../../services/getEquivalent";

const SelectRateComparisonVehicle = ({
  id = "rate-comparison-vehicle",
  electricVehicles,
  selectedVehicle,
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const initial = selectedVehicle?.make;

  const [selectedMake, setSelectedMake] = useState(selectedVehicle.make);
  const [models, setModels] = useState(
    electricVehicles.filter((vehicle) => vehicle.make === initial)
  );

  let makes = [];

  electricVehicles.forEach((vehicle) => {
    if (makes.includes(vehicle.make)) return;
    makes.push(vehicle.make);
  });

  const switchModels = (make) => {
    setModels(electricVehicles.filter((vehicle) => vehicle.make === make));
  };

  const loadEquivalentVehicleData = async (handle, zip) => {
    const equivalentVehicle = await getEquivalent(handle, zip);
    if (!electricVehicles) return;

    userPrefs.set({
      equivalentGasVehicleRateComparison:
        equivalentVehicle.fossil_fuel_efficiency,
    });
  };

  useEffect(() => {
    if (selectedVehicle.make !== models[0].make) {
      switchModels(selectedVehicle.make);
    }

    if (selectedVehicle) {
      loadEquivalentVehicleData(
        selectedVehicle.handle,
        userPrefs.get("zipcode") || "94903"
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVehicle]);

  useEffect(() => {
    switchModels(selectedMake);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMake]);

  useEffect(() => {
    userPrefs.set({
      vehicleIdForRateComparison: models[0].electric_vehicle_id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models]);

  return (
    <>
      <Select
        value={selectedVehicle.make}
        id={`${id}-make`}
        optionNames={makes.map((make) => {
          return `${make}`;
        })}
        optionValues={makes.map((make) => {
          return `${make}`;
        })}
        handler={(e) => {
          setSelectedMake(e.target.value);
        }}
        label="EV Make Considered"
        isLarge
        isSpecial
      />

      <Select
        value={selectedVehicle.electric_vehicle_id}
        id={`${id}-model`}
        optionNames={models.map((ev) => {
          return `${FormatCarModelAndTrim(ev)}`;
        })}
        optionValues={models.map((ev) => ev.electric_vehicle_id)}
        handler={(e) => {
          const newVehicle = models.find(
            (ev) => ev.electric_vehicle_id === Number(e.target.value)
          );
          userPrefs.set({
            vehicleIdForRateComparison: newVehicle.electric_vehicle_id,
          });
        }}
        label="EV Model Considered"
        isLarge
        isSpecial
      />
    </>
  );
};

export default SelectRateComparisonVehicle;
