import React, { useContext } from 'react';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import Range from '../../../../components/shared/InputElements/Range';

const SlideCurrentMonthlyBill = (id) => {
  const userPrefs = useContext(UserPrefsContext);
  return (
    <Range
      id={id.id}
      value={userPrefs.get("currentMonthlyBill")}
      handler={(e) => userPrefs.set({currentMonthlyBill: e.target.value})}
      label="Home Monthly Electric Bill"
      rangeMin={10}
      rangeMax={600}
      rangeStep={10}
      description={val => `$${val} / month`}
      tooltip={"What is your typical monthly bill, regardless of season? We use this to estimate a baseline for your current energy consumption."}
    />
  );
};

export default SlideCurrentMonthlyBill;

