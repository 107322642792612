import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./Homepage.scss";
import HomepageBigPromise from "./components/HomepageBigPromise";
import HomepageIncentives from "./components/HomepageIncentives";
import getLocalVehicles from "../../../functions/vehicle/getLocalVehicles";
import ShuffleFlatArray from "../../../utils/Helpers/ShuffleFlatArray";
import VehiclesCarousel from "../../../components/VehicleCarousel/VehicleCarousel";
import ChargingMap from "../../../components/LocationMap/ChargingMap";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { Link } from "react-router-dom";

const Homepage = ({
  homePageBannerImage,
  electricVehicles,
  incentives,
  userLocation,
  ip,
  uuid,
}) => {
  const [vehicles, setVehicles] = useState([]);
  const setElectricVehicles = () => {
    if (electricVehicles) setVehicles(ShuffleFlatArray(electricVehicles));
  };

  useEffect(() => {
    setElectricVehicles();
  });

  const renderDetails =
    electricVehicles && incentives ? (
      <>
        <VehiclesCarousel electricVehicles={getLocalVehicles(vehicles)} />
        <HomepageIncentives incentives={incentives} />
      </>
    ) : (
      <section className="container">
        <LoadingSpinner />
      </section>
    );

  return (
    <>
      <HomepageBigPromise homePageBannerImage={homePageBannerImage} />

      {renderDetails}

      <div id="HomepageChargingStations">
        <div className="container">
          <div className="textContainer">
            <h2>Recharge at home, or on the go.</h2>
            <p>
              Plugging in is getting more convenient every day. Enter your
              location to see all of the charging stations near you, or click
              below to shop available home chargers.
            </p>
            <Link style={{ zIndex: "2" }} to="/home-chargers" role="button">
              <div className="button">
                Shop Chargers
              </div>
            </Link>
          </div>

          <ChargingMap hideList userLocation={userLocation} />
        </div>
      </div>
    </>
  );
};

export default Homepage;

Homepage.propTypes = {
  homePageBannerImage: PropTypes.string,
  electricVehicles: PropTypes.array,
  incentives: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
