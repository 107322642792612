import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import getFilteredData from "../../../../utils/Helpers/getFilteredData";
import IncentiveCatalog from "../../../../components/IncentiveCatalog/IncentiveCatalog";
import bgImage from "../../../assets/images/homepageIncentives.png";

const HomepageIncentives = ({ incentives }) => {
  incentives = getFilteredData(
    [
      { field: "grantor", value: "Federal", count: 1 },
      { field: "grantor_type", value: "State", count: 2 },
      { field: "grantor_type", value: "Power Supplier", count: 3 },
    ],
    incentives
  );

  return (
    <section
      id="HomepageIncentives"
      style={{
        backgroundImage: "url(" + bgImage + ")",
        backgroundColor: 'black',
      }}
    >
      <div className="gradient" />
      <div className="container">
        <div className="textContainer">
          <h2>Maximize potential EV incentives and tax credits.</h2>
          <p>
            See how much you could save getting behind the wheel of an EV,
            whether you are buying or leasing. Incentives are personalized for
            where you live.
          </p>
          <Link style={{ zIndex: "2" }} to="/incentives" role="button">
            <div className="button">Personalize Incentives</div>
          </Link>
        </div>
        <IncentiveCatalog incentives={incentives} category="all" />
      </div>
    </section>
  );
};

export default HomepageIncentives;

HomepageIncentives.propTypes = {
  incentives: PropTypes.array,
};
