import React, { useContext } from "react";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import Switch from "react-switch";

import ToolTip from "../../../components/shared/ToolTip/ToolTip";
import IconBEV from "../../assets/images/icons/icon-electric.png";
import IconPHEV from "../../assets/images/icons/icon-hybrid.png";
import { FormattedMessage } from "react-intl";

const EVFilterControls = ({vehicles}) => {
  const userPrefs = useContext(UserPrefsContext);
  const formFactorFilterData = userPrefs.get("vehicleFormFactorFilters");
  const fuelTypeFilterData = userPrefs.get("vehicleFuelTypeFilters");


  const updateFormFactorFilter = (e, key) => {
    e.preventDefault();
    let newFilters = Object.assign({}, formFactorFilterData);
    newFilters[key] = !newFilters[key];
    userPrefs.set({
      vehicleFormFactorFilters: newFilters
    });
  };

  const renderedFormFactorFilters = Object.keys(formFactorFilterData).map(
    (filterKey, i) => {
      const filterName =
        filterKey === "suv"
          ? "SUV"
          : `${filterKey.charAt(0).toUpperCase()}${filterKey.substr(1)}`;

      return (
              <button
              className={formFactorFilterData[filterKey] ? "btn filter active" : "btn filter"}
              key={i}
              aria-pressed={!!formFactorFilterData[filterKey]}
              onClick={(e) => updateFormFactorFilter(e, filterKey)}
              >
              {filterName}
              </button>
      );
    }
  );

  const updateFuelTypeFilter = (checked, event, selectedKey) => {
    const newFilters = Object.keys(fuelTypeFilterData).reduce(function(
      acc,
      key
    ) {
      // Toggle selected key, set all others to false
      acc[key] = key === selectedKey ? !fuelTypeFilterData[key] : false;
      return acc;
    },
    {});

    userPrefs.set({
      vehicleFuelTypeFilters: newFilters
    });
  };

  const renderedFuelTypeFilters = Object.keys(fuelTypeFilterData).map(
    (filterKey, i) => {
      let filterName = "";
      let filterIconSrc = "";
      switch (filterKey) {
        case "bev":
          filterName = "All-Electric";
          filterIconSrc = IconBEV;
          break;
        case "phev":
          filterName = "Plug-in Hybrid";
          filterIconSrc = IconPHEV;
          break;
        default:
      }

      return (
        <div key={i} className="filter-switch">
            <div className={i !== 0 ? "filter-label" : "filter-label first"}>
                <span className="badge">
                    <img src={filterIconSrc} alt="" />
                </span>
                <label htmlFor={filterKey} className="name">{filterName}</label>
                <div className="switch">
                  <Switch
                    id={filterKey}
                    checked={fuelTypeFilterData[filterKey] ? true : false}
                    onChange={updateFuelTypeFilter}
                    onColor="#0079C1"
                    offColor="#333333"
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
      );
    }
  );

  
  return (
    <div className="input-well ev-filter-controls">
      <p className="h2 bottom-margin">Filter</p>
      <form>
        <div className="form-group">
          <span className="label-style">
            Fuel
            <ToolTip
              message="All-electric vehicles use electricity only. Plug-in hybrids can use electricity and gasoline."
              id="fuel_tooltip"
            />
          </span>
          <div className="btn-block-container">{renderedFuelTypeFilters}</div>
        </div>
        <div className="form-group">
          <span className="label-style">Type</span>
          <div className="btn-grid-container">{renderedFormFactorFilters}</div>
        </div>
        <div className="top-margin">
            <FormattedMessage
            id="evs.disclaimer"
            defaultMessage="Vehicles displayed may not reflect actual availability. We do not endorse or recommend any specific vehicle or car manufacturer."
            description="EVs Disclaimer"
            />
        </div>
      </form>
    </div>
  );
};

export default EVFilterControls;
