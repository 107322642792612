import React from 'react';
import { FormatCarModelAndTrim, FormatAsDollars } from '../../../utils/Helpers/Format';
import "./RateComparisonBanner.scss";

const RateComparisonBanner = ({
  rateTotals,
  featuredRateId,
  selectedVehicle
}) => {
  const currentRateTotal = rateTotals[0];
  let comparedRateTotal;
  if (featuredRateId && rateTotals.find(total => total.id === featuredRateId)) {
    comparedRateTotal = rateTotals.find(total => total.id === featuredRateId);
  } else {
    comparedRateTotal = rateTotals.find(total => {
      return total.monthly === Math.min(...rateTotals.map(t => t.monthly));
    });
  }

  const currentAnnualCost = currentRateTotal.monthly * 12;
  const comparedAnnualCost = comparedRateTotal.monthly * 12;

  if (comparedAnnualCost >= currentAnnualCost) return null;

  const actionText = comparedRateTotal.id === currentRateTotal.id.replace("-current", "") ?
    "adding a" :
    `switching to ${comparedRateTotal.title} with a`;

  return (
    <div className="RateComparisonBanner">
      You can
      {" "}
      <span className="highlight">
       save {FormatAsDollars((currentAnnualCost - comparedAnnualCost).toFixed(0))} per year
      </span>
      {" "}
      by
      {" "}
      {actionText}
      {" "}
      {`${FormatCarModelAndTrim(selectedVehicle)}`}
    </div>
  );
};

export default RateComparisonBanner;
