import React, { useMemo } from "react";
import PropTypes from "prop-types";
import IncentiveCard from "./../IncentiveCard/IncentiveCard";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";

// const hardcodedIncentives = [
//   {
//     grantor: "Consumers Energy",
//     grantor_type: "Power Supplier",
//     applicant_type: "Passenger",
//     name: "Plug-In Electric Vehicle (PEV) Charging Rate Reduction",
//     description:
//       "Consumers Energy offers a special time-of-use rate option for EV owners.",
//     type: "Discounted Electric Rate",
//     support_for: "Electricity",
//     typical_amount: "N/A",
//     limitations: "",
//     details_url:
//       "https://www.consumersenergy.com/residential/rates/electric-rates-and-programs/rate-plan-options/smart-hours",
//     applicable_to_new_vehicles: false,
//     applicable_to_used_vehicles: false,
//     income_dependent: false,
//     applicable_to_fcevs: false,
//     available: true,
//   },
//   {
//     grantor: "Consumers Energy",
//     grantor_type: "Power Supplier",
//     applicant_type: "Passenger",
//     name: "Residential Charger Rebate",
//     description:
//       "Consumers Energy Co is offering a $500 rebate for customers installing a Level 2 charger at their residence.",
//     type: "Rebate",
//     support_for: "Charging station",
//     typical_amount: "Up to $500",
//     limitations: "",
//     details_url:
//       "https://www.consumersenergy.com/residential/programs-and-services/electric-vehicles/home-charger-rebates",
//     applicable_to_new_vehicles: true,
//     applicable_to_used_vehicles: true,
//     income_dependent: false,
//     applicable_to_fcevs: false,
//     available: true,
//   },
// ];


const IncentiveCatalog = ({ incentives, titleText }) => {
  const renderCards = useMemo(() => {

    // hardcodedIncentives.forEach((incentive) => {
    //   incentives.unshift(incentive);
    // });

    return incentives.map((incentive, index) => {
      return (
        <IncentiveCard
          isGreen={incentive.grantor === "Consumers Energy"}
          displayNone={incentive.grantor === "DTE Energy"}
          incentive={incentive}
          key={index}
        />
      );
    });
  }, [incentives]);

  if (!incentives) return <LoadingSpinner />;

  return (
    <div className="row mt-3 mb-3">
      <div className="col-sm-12">
        {titleText && <p className="h3 text-left">{titleText}</p>}
        <div className="d-flex justify-content-center flex-wrap">
          {renderCards}
        </div>
      </div>
    </div>
  );
};

export default IncentiveCatalog;

IncentiveCatalog.propTypes = {
  incentives: PropTypes.array,
  titleText: PropTypes.element,
  includeTotal: PropTypes.bool,
};
