import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Uuid from "../../utils/Uuid/Uuid";
import electricIcon from "../../client_customizations/assets/images/icons/icon-electric.png";
import gasolineIncon from "../../client_customizations/assets/images/icons/icon-barrel.png";
import phevIcon from "../../client_customizations/assets/images/icons/icon-hybrid.png";
import Triangle from "../../client_customizations/assets/images/Triangle.svg";
import Select, { components } from "react-select";

import "./CompareVehicles.scss";

import { useIntl } from "react-intl";

const vehicleMakeOptions = (vehicles) => {
  let vehicleMakes = new Set();
  vehicles.forEach((vehicle) => {
    if (vehicle) {
      vehicleMakes.add(vehicle.make);
    }
  });

  vehicleMakes = [...vehicleMakes];

  vehicleMakes.sort((a, b) => {
    return a.localeCompare(b);
  });

  return vehicleMakes.map((make) => {
    return (
      <option value={make} key={make}>
        {make}
      </option>
    );
  });
};

const vehicleModelOptions = (vehicles, makeOption, compareUsedVehicles) => {
  if (makeOption) {
    vehicles = vehicles.filter((vehicle) => {
      return vehicle.make === makeOption;
    });

    vehicles.sort((a, b) => {
      return a.model.localeCompare(b.model);
    });

    return vehicles.map((vehicle) => {
      const modelAndTrim = vehicle.trim
        ? `${vehicle.model} ${vehicle.trim}`
        : vehicle.model;
      return (
        <option
          value={modelAndTrim}
          key={Uuid()}
          data-handle={
            compareUsedVehicles ? vehicle.display_name : vehicle.handle
          }
        >
          {modelAndTrim}
        </option>
      );
    });
  }
};
const vehicleYearOptions = (vehicles, modelOption) => {
  if (modelOption) {
    vehicles = vehicles.filter(
      (vehicle) =>
        vehicle.display_name.trim().toLowerCase() === modelOption.toLowerCase()
    );
    vehicles.sort((v1, v2) => v2.model_year - v1.model_year);

    return vehicles.map((vehicle) => {
      return (
        <option
          value={vehicle.model_year}
          key={Uuid()}
          data-handle={vehicle.handle}
        >
          {vehicle.model_year}
        </option>
      );
    });
  }
};

const ComparedVehicle = ({
  electricVehicles,
  gasolineVehicles,
  setVehicle,
  compareUsedVehicles,
  usedElectricVehicles
}) => {

  const [evOptions, setEvOptions] = useState([]);
  const [fuelOption, setFuelOption] = useState("");
  const [makeOption, setMakeOption] = useState("");
  const [modelOption, setModelOption] = useState("");
  const [yearOption, setYearOption] = useState("");

  useEffect(() => {
    setFuelOption("");
    setMakeOption("");
    setModelOption("");
    setYearOption("");
  }, [compareUsedVehicles]);

  useEffect(() => {
    let vehicles = compareUsedVehicles
      ? usedElectricVehicles
      : electricVehicles.concat(gasolineVehicles);

    if (fuelOption) {
      if (fuelOption === "PHEV" || fuelOption === "BEV") {
        vehicles = electricVehicles.filter((vehicle) => {
          return vehicle.fuel === fuelOption;
        });
      } else if (fuelOption === "gas") {
        vehicles = gasolineVehicles.filter((vehicle) => {
          return vehicle.fuel === fuelOption;
        });
      }
    }

    setEvOptions(vehicles);
  }, [compareUsedVehicles, electricVehicles, gasolineVehicles,usedElectricVehicles, fuelOption]);

  const allVehicles = compareUsedVehicles
    ? electricVehicles
    : electricVehicles.concat(gasolineVehicles);

  const intl = useIntl();

  if (allVehicles.length === 0) return null;

  const onChangeFuelType = e => {
    const newFuelType = e.value;
    let filteredFuelVehicles;

    if (newFuelType === "PHEV" || newFuelType === "BEV") {
      filteredFuelVehicles = electricVehicles.filter((vehicle) => {
        return vehicle.fuel === newFuelType;
      });
    } else if (newFuelType === "gas") {
      filteredFuelVehicles = gasolineVehicles.filter((vehicle) => {
        return vehicle.fuel === newFuelType;
      });
    } else {
      filteredFuelVehicles = allVehicles;
    }

    setFuelOption(newFuelType);
    setMakeOption("");
    setModelOption("");
    setEvOptions(filteredFuelVehicles);
  };

  const onChangeMake = (e) => {
    const newMakeType = e.target.value;

    setMakeOption(newMakeType);
    setModelOption("");
    setYearOption("");
  };

  const onChangeModelType = (e) => {
    const newModelType = e.target.value;
    const vehicleHandle =
      e.target.options[e.target.selectedIndex].dataset.handle;

    if (newModelType === "Select a Model") {
      setModelOption(newModelType);
      return;
    }

    const selectedVehicle = evOptions.find((vehicle) => {
      return vehicle.handle === vehicleHandle;
    });

    setFuelOption(selectedVehicle.fuel);
    setModelOption(newModelType);
    setVehicle(selectedVehicle);
    setYearOption("");
    !compareUsedVehicles && setVehicle(selectedVehicle);
  };
  const onChangeYear = (e) => {
    const newYear = e.target.value;
    setYearOption(newYear);
    const vehicleHandle =
      e.target.options[e.target.selectedIndex].dataset.handle;

    if (newYear === "Select a Year") {
      setYearOption(newYear);
      return;
    }

    const selectedVehicle = evOptions.find((vehicle) => {
      return vehicle.handle === vehicleHandle;
    });

    setVehicle(selectedVehicle);
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={Triangle} alt="" height="7px" width="7px" />
      </components.DropdownIndicator>
    );
  };
  const Input = (props) => {
    return (
      <components.Input {...props} title="Select Fuel Type" />
    )
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
      color: '#666666',
    }),
    placeholder: (base) => ({
      ...base,
      color: '#666666',
    }),
  };
  const options = [
    {
      value: intl.formatMessage({
        id: "vehicle.plugInHybrid.value",
        defaultMessage: "PHEV",
      }),
      label: (
        <div>
          <img src={phevIcon} height="15px" width="15px" alt="" /> Plug In Hybrid
        </div>
      ),
    },
    {
      value: intl.formatMessage({
        id: "vehicle.allElectric.value",
        defaultMessage: "BEV",
      }),
      label: (
        <div>
          <img src={electricIcon} height="15px" width="15px" alt="" /> All Electric
        </div>
      ),
    },
    {
      value: intl.formatMessage({
        id: "vehicle.gas.value",
        defaultMessage: "gas",
      }),
      label: (
        <div>
          <img src={gasolineIncon} height="15px" width="15px" alt="" /> Gasoline
        </div>
      ),
    },
  ];

  return (
    <div className="ComparedVehicle">
      <div className="row" style={{ justifyContent: "center" }}>
        <div className="col-sm-10">
          <p>
            {intl.formatMessage({
              id: "compareVehicles.selectFuelTitle",
              defaultMessage: "FUEL TYPE",
            })}
          </p>
          <div className="form-group open-sans">
            <Select
              defaultValue={fuelOption}
              onChange={onChangeFuelType}
              options={options}
              components={{ DropdownIndicator, IndicatorSeparator: () => null, Input }}
              placeholder={"Select Fuel Type"}
              styles={customStyles}
            />
          </div>

          <div className="form-group">
            <p className="input-label">
              {intl.formatMessage({
                id: "compareVehicles.selectManufacturer",
                defaultMessage: "Manufacturer",
              })}
            </p>
            <select
              className="form-control form-control-special"
              value={makeOption}
              onChange={onChangeMake}
              title={intl.formatMessage({
                id: "compareVehicles.selectMake",
                defaultMessage: "CAR MAKE",
              })}
            >
              <option defaultValue="">
                {intl.formatMessage({
                  id: "compareVehicles.selectMake",
                  defaultMessage: "CAR MAKE",
                })}
              </option>
              {vehicleMakeOptions(evOptions)}
            </select>
          </div>

          <div className="form-group">
            <p className="input-label">
              {intl.formatMessage({
                id: "compareVehicles.selectModelTitle",
                defaultMessage: "CAR MODEL",
              })}
            </p>
            <select
              className="form-control form-control-special"
              value={modelOption}
              onChange={onChangeModelType}
              title={intl.formatMessage({
                id: "compareVehicles.selectModel",
                defaultMessage: "Select a Model",
              })}
            >
              <option defaultValue="">
                {intl.formatMessage({
                  id: "compareVehicles.selectModel",
                  defaultMessage: "Select a Model",
                })}
              </option>
              {vehicleModelOptions(evOptions, makeOption)}
            </select>
          </div>
          <div className="form-group">
            {compareUsedVehicles && (
              <div>
                <p className="input-label">
                  {intl.formatMessage({
                    id: "compareVehicles.selectYearTitle",
                    defaultMessage: "YEAR",
                  })}
                </p>
                <select
                  className="form-control form-control-special"
                  value={yearOption}
                  onChange={onChangeYear}
                  disabled={modelOption === "" ? true : null}
                  title={intl.formatMessage({
                    id: "compareVehicles.selectYear",
                    defaultMessage: "Select Year",
                  })}
                >
                  <option defaultValue="">
                    {intl.formatMessage({
                      id: "compareVehicles.selectYear",
                      defaultMessage: "Select Year",
                    })}
                  </option>
                  {vehicleYearOptions(evOptions, modelOption)}
                </select>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ComparedVehicle.propTypes = {
  vehicle: PropTypes.object,
  electricVehicles: PropTypes.array,
  setVehicle: PropTypes.func,
  compareUsedVehicles: PropTypes.bool,
  usedElectricVehicles: PropTypes.array,
};

ComparedVehicle.defaultProps = {
  vehicle: {},
  electricVehicles: [],
  usedElectricVehicles: [],
};

export default ComparedVehicle;
