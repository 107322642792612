import React, { useContext } from 'react';
import Select from '../../../../components/shared/InputElements/Select';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';

const SelectChargingPattern = ({ id = "rate-comparison-charging-pattern", chargingPatterns }) => {
  const userPrefs = useContext(UserPrefsContext);
  return (
    <Select
      id={id}
      value={userPrefs.get('chargingPatternId')}
      optionNames={chargingPatterns.map(p => p.title)}
      optionValues={chargingPatterns.map(p => p.id)}
      handler={(e) => userPrefs.set({chargingPatternId: e.target.value})}
      label="Charging Pattern"
      tooltip={"Most Consumers Energy customers use this residential rate, which features a simple fixed rate for all hours of the day."}
    />
  );
};

export default SelectChargingPattern;
