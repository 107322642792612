import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";

import ChargerCatalog from "../../components/ChargerCatalog/ChargerCatalog";
import ChargerSortControls from "./../../components/ChargerSortControls/ChargerSortControls";
import HomeChargersFilterControls from "./../../components/HomeChargersFilterControls/HomeChargersFilterControls";
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import sortChargers from "../../functions/chargers/Sort/sortChargers";
import fitlerChargers from "../../functions/chargers/Filter/filterChargers";
import { FormattedMessage } from "react-intl";

import "./HomeChargers.scss";

const HomeChargers = ({ electricVehicles, homeChargers, ip, uuid }) => {
       const userPrefs = useContext(UserPrefsContext);
  const [makeOption, setMakeOption] = useState("");
  const [modelOption, setModelOption] = useState("");
  const [ev, setEv] = useState(null);

  if (!electricVehicles || !homeChargers) {
       return <LoadingSpinner />;
  }

       const sortedhomeChargers = sortChargers(
              homeChargers,
              userPrefs.get("chargerSortType"),
              userPrefs.get("chargerSortDirection"),
       );

       const filteredAndSortedChargers = fitlerChargers(sortedhomeChargers, {
              chargerFormFactorFilters: userPrefs.get("chargerFormFactorFilters"),
              chargerTypeFilters: userPrefs.get("chargerTypeFilters"),
              chargerWifi: userPrefs.get("chargerWifiFilter"),
              chargerBudget: userPrefs.get("chargerBudget"),
              chargerCordLength: userPrefs.get("chargerCordLength")
       });

  const onChangeMake = (e) => {
    const newMakeType = e.target.value;
    setMakeOption(newMakeType);
  };

  const onChangeModelType = (e) => {
    const newModelType = e.target.value;
    const vehicleHandle = e.target.options[e.target.selectedIndex].dataset.handle;

    if (newModelType === "Select a Model") {
      setModelOption(newModelType);
      return;
    }

    const selectedVehicle = electricVehicles.find((vehicle) => {
      return vehicle.handle === vehicleHandle;
    });

    setModelOption(newModelType);
    setEv(selectedVehicle);
  };

  const renderOptions = (
    <>
      <HomeChargersFilterControls
        homeChargers={filteredAndSortedChargers}
        chargersAmount={filteredAndSortedChargers.length}
        makeOption={makeOption}
        onChangeMake={onChangeMake}
        modelOption={modelOption}
        onChangeModelType={onChangeModelType}
        electricVehicles={electricVehicles}
      />
    </>
  );

  return (
    <section className="container HomeChargers">
      <div className="row mb-3">
        <div className="col-sm-12 text-center text-container">
          <h2>
            <FormattedMessage
              id="homeChargers.title"
              defaultMessage="Home Chargers"
              description="Home Chargers Title"
            />
          </h2>
          <p className="subtitle">
            Find the full range of chargers available below.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 d-block d-lg-none text-center">
          <ModalComponent buttonText="Filters" titleText="Refine Filters">
            {renderOptions}
          </ModalComponent>
          <br />
        </div>
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          {renderOptions}
        </div>

        <div
          className="col-xl-9 col-lg-8 col-sm-12"
          style={{ paddingLeft: 10 }}
        >
          <div className="row">
            <div className="col">
              <div className="pull-right">
                <ChargerSortControls />
              </div>
            </div>
          </div>
          <ChargerCatalog homeChargers={filteredAndSortedChargers} selectedVehicle={ev} />
        </div>
      </div>
    </section>
  );
};

export default HomeChargers;

HomeChargers.propTypes = {
  electricVehicles: PropTypes.array,
  homechargers: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
