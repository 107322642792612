import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormatAsCents } from "../../../utils/Helpers/Format";
import { useIntl } from "react-intl";

import Range from "../../shared/InputElements/Range";

const descriptionFn = val => FormatAsCents(val / 100);

const SlideGasolinePrice = ({
  id = "gasoline-price-range",
  description = descriptionFn,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const intl = useIntl();
  return (
    <Range
      id={id}
      value={userPrefs.get("gasolinePriceInCentsPerGal")}
      label={process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "vehicle.gasoline.metricPrice", defaultMessage: "Gasoline Price ($/L)" }) : intl.formatMessage({ id: "vehicle.gasoline.price", defaultMessage: "Gasoline Price ($/gal)" })}
      rangeMin={100}
      rangeMax={600}
      rangeStep={5}
      description={description}
      tooltip={"Enter your local gas price. Our default is based on your region’s most recent gas price, updated montly."}
      handler={e =>
        userPrefs.set({ gasolinePriceInCentsPerGal: e.target.value })
      }
      {...rest}
    />
  );
};

export default SlideGasolinePrice;

SlideGasolinePrice.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func,
  tooltip: PropTypes.string
};
