import React from 'react';

const ScheduleDDetails = () => {
  return (
    <div className="ScheduleDDetails">
      <h2>Rate Plan: Summer Rate (Standard)</h2>
      <p>The Summer Rate ensures a cleaner energy future and encourages you to take advantage of lower-cost electricity available at certain times of the day. Most customers will only see a small increase in their monthly bill, without changing when or how they use electricity.</p>

      <h2>How it works:</h2>
      <p>The Summer Rate runs from June 1 through September 30. This period consists of “on-peak” and “off-peak” rate prices:</p>
      <p>“On-peak” rate price: From 2 to 7 p.m., Monday through Friday, the electricity rate will be about 1.5 times higher than the “off-peak” rate price</p>
      <p>“Off-peak” rate price: Customers will pay a lower rate price for electricity used outside of on-peak times and during the non-summer months of October through May</p>

      <h2>What it means for you:</h2>
      <p>With this rate, when you use electricity can be just as important as how much you use. We're encouraging customers to save money by reducing electricity use during this on-peak time, or shifting use to mornings, nights and weekends.</p>

      <a href="https://www.consumersenergy.com/residential/rates/electric-rates-and-programs/summer-time-of-use-rate?utm_campaign=summertour&utm_source=summerrate&utm_medium=vanity-url&utm_content=summerrate" rel="noopener noreferrer" target="_blank"><button className='details-button'>Learn More</button></a>
    </div>
  );
};

export default ScheduleDDetails;
